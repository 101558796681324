const TRANSLATIONS = {
  common: {
    "workspaces-name": "Nom des espaces de travail",
    error: "erreur",
    success: "succès",
    user: "Utilisateur",
    selection: "Sélection de modèle",
    saving: "Enregistrement...",
    save: "Enregistrer les modifications",
    previous: "Page précédente",
    next: "Page suivante",
  },

  // Popup Confirmation
  deleteWorkspaceConfirmation:
    "Êtes-vous sûr de vouloir supprimer {{name}} ?\nAprès cela, il sera indisponible dans cette instance.\n\nCette action est irréversible.",
  deleteConfirmation:
    "Êtes-vous sûr de vouloir supprimer {{username}} ?\nAprès cela, ils seront déconnectés et ne pourront plus utiliser cette instance.\n\nCette action est irréversible.",
  suspendConfirmation:
    "Êtes-vous sûr de vouloir suspendre {{username}} ?\nAprès cela, ils seront déconnectés et ne pourront pas se reconnecter à cette instance tant qu'un administrateur ne les aura pas réactivés.",
  flushVectorCachesWorkspaceConfirmation:
    "Êtes-vous sûr de vouloir vider les caches vectoriels pour cet espace de travail ?",

  // Setting Sidebar menu items.
  settings: {
    title: "Paramètres de l'instance",
    system: "Paramètres généraux",
    invites: "Invitations",
    users: "Utilisateurs",
    workspaces: "Espaces de travail",
    "workspace-chats": "Chats de l'espace de travail",
    customization: "Personnalisation",
    "api-keys": "API Développeur",
    llm: "LLM",
    transcription: "Transcription",
    embedder: "Incorporateur",
    "text-splitting": "Fractionnement & Division du texte",
    "vector-database": "Base de données vectorielle",
    embeds: "Intégration de Chat",
    "embed-chats": "Historique des Chats intégrés",
    security: "Sécurité",
    "event-logs": "Journaux d'événements",
    privacy: "Confidentialité & Données",
    "ai-providers": "Fournisseurs d'IA",
    "agent-skills": "Compétences de l'agent",
    admin: "Administrateur",
    tools: "Outils",
    audio: "Préférence audio",
    "link-settings": "Paramètres",
    "default-settings": "Paramètres par Défaut",
    "browser-extension": "Browser Extension",
    "prompt-upgrade-llm": "LLM d'amélioration de prompt",
  },

  // Qura buttons,
  qura: {
    "copy-to-cora": "Vérification des sources Qura",
    "qura-status": "Le bouton Qura est ",
    "copy-option": "Option de copie",
    "option-quest": "Question",
    "option-resp": "Reponse",
    "role-description":
      "Ajoutez un bouton Qura pour demander des réponses sur Qura.law",
  },

  // Page Definitions
  login: {
    "multi-user": {
      welcome: "Bienvenue à",
      "placeholder-username": "Adresse e-mail",
      "placeholder-password": "Mot de passe",
      login: "Connexion",
      validating: "Validation...",
      "forgot-pass": "Mot de passe oublié",
      reset: "Réinitialiser",
    },
    "sign-in": {
      start: "Connectez-vous à votre compte",
      end: "compte.",
    },
    button: "connexion",
    password: {
      forgot: "MOT DE PASSE OUBLIÉ ?",
      contact: "Veuillez contacter l'administrateur système.",
    },
    publicMode: "Mode Public",
  },

  thread_name_error:
    "Le nom du fil doit comporter au moins 3 caractères et ne contenir que des lettres, des chiffres, des espaces ou des traits d'union.",

  "new-workspace": {
    title: "Nouvel espace de travail",
    placeholder: "Mon espace de travail",
    "legal-areas": "Legal Areas",
    create: {
      title: "Créer un nouvel espace de travail",
      description:
        "Après avoir créé cet espace de travail, seuls les administrateurs pourront le voir. Vous pouvez ajouter des utilisateurs après sa création.",
      error: "Erreur : ",
      cancel: "Annuler",
      "create-workspace": "Créer un espace de travail",
    },
  },

  "workspace-chats": {
    welcome: "Bienvenue dans votre nouvel espace de travail.",
    "desc-start": "Pour commencer soit",
    "desc-mid": "télécharger un document",
    "desc-or": "ou",
    start: "Pour commencer",
    "desc-end": "envoyer un chat.",
    prompt: {
      send: "Envoyer",
      "send-message": "Envoyer le message",
      placeholder: "Demander des informations juridiques",
      slash: "Voir toutes les commandes disponibles pour discuter.",
      "change-size": "Changer la taille du texte",
      reset: "/réinitialiser",
      clear: "Effacer votre historique de chat et commencer un nouveau chat",
      "new-preset": "Ajouter un nouveau préréglage",
      command: "Commande",
      description: "Description",
      save: "sauver",
      small: "Petit",
      normal: "Normal",
      large: "Grand",
      attach: "Joindre un fichier à cette conversation",
      upgrade: "Améliorez votre prompt",
      upgrading: "Mise à jour de votre prompt",
      "original-prompt": "Invite original:",
      "upgraded-prompt": "Invite améliorée:",
      "edit-prompt":
        "Vous pouvez modifier le nouveau prompt avant de le soumettre",
    },
  },

  contextual: {
    checkbox: {
      label: "Guhuza n'ibindi",
      hint: "Koresha uburyo bwo guhuza n'ibindi kugira ngo wongerere imikorere y'ibindi bintu",
    },
    systemPrompt: {
      label: "Ibibazo bya Sisitemu",
      placeholder: "Andika igisubizo...",
      description:
        "Urugero: Nyabuneka, tanga ibisobanuro bigufi kugira ngo ushyire iyi gice mu nyandiko yose kugira ngo bikoroshye gushaka igice. Subiza gusa n'ibisobanuro bigufi ntakindi.",
    },
    userPrompt: {
      label: "Ibibazo by'Ukoresha",
      placeholder: "Andika igisubizo...",
      description:
        "Exemple :<document>\n{file}\n</document>\nNgiki igice turashaka gushyira mu nyandiko yose\n<chunk>\n{chunk}\n</chunk>",
    },
  },

  header: {
    account: "Compte",
    login: "Connexion",
    "sign-out": "Déconnexion",
  },

  workspace: {
    title: "Espaces de travail de l'instance",
    description:
      "Ce sont tous les espaces de travail qui existent sur cette instance. La suppression d'un espace de travail effacera tous ses chats et paramètres associés.",
    "new-workspace": "Nouveau Espace de Travail",
    name: "Nom",
    link: "Lien",
    users: "Utilisateurs",
    type: "Type",
    "created-on": "Créé le",
    save: "Enregistrer les modifications",
    cancel: "Annuler",
    deleted: {
      title: "Espace de travail introuvable !",
      description:
        "Il semble qu'un espace de travail avec ce nom ne soit pas disponible.",
      homepage: "Retourner à la page d'accueil",
    },
    "no-workspace": {
      title: "Aucun espace de travail disponible",
      description: "Vous n'avez pas encore accès à un espace de travail.",
      "contact-admin":
        "Veuillez contacter votre administrateur pour demander l'accès.",
      "learn-more": "En savoir plus sur les espaces de travail",
    },
  },

  // Workspace Settings menu items
  "workspaces-settings": {
    general: "Paramètres généraux",
    chat: "Paramètres du chat",
    vector: "Base de données vectorielle",
    members: "Membres",
    agent: "Configuration de l'agent",
    "general-settings": {
      "workspace-name": "Nom de l'espace de travail",
      "desc-name":
        "Cela ne changera que le nom d'affichage de votre espace de travail.",
      "assistant-profile": "Image de profil de l'assistant",
      "assistant-image":
        "Personnalisez l'image de profil de l'assistant pour cet espace de travail.",
      "workspace-image": "Image de l'espace de travail",
      "remove-image": "Supprimer l'image de l'espace de travail",
      delete: "Supprimer l'espace de travail",
      deleting: "Suppression de l'espace de travail...",
      update: "Mettre à jour l'espace de travail",
      updating: "Mise à jour de l'espace de travail...",
    },
    "chat-settings": {
      type: "Type de chat",
      private: "Privé",
      standard: "Standard",
      "private-desc-start": "accordera manuellement l'accès à",
      "private-desc-mid": "seulement",
      "private-desc-end": "certains utilisateurs spécifiques.",
      "standard-desc-start": "accordera automatiquement l'accès à",
      "standard-desc-mid": "tous",
      "standard-desc-end": "les nouveaux utilisateurs.",
    },
    users: {
      manage: "Gérer les utilisateurs",
      "workspace-member": "Aucun membre de l'espace de travail",
      username: "Adresse e-mail",
      role: "Rôle",
      date: "Date d'ajout",
      users: "Utilisateurs",
      search: "Rechercher un utilisateur",
      "no-user": "Aucun utilisateur trouvé",
      select: "Tout sélectionner",
      unselect: "Tout déselectionner",
      save: "Sauvegarder",
    },
    "linked-workspaces": {
      title: "Espaces Liés",
      description:
        "Si des espaces de travail sont liés, les données juridiques pertinentes pour la requête seront automatiquement récupérées de chaque domaine juridique lié. Notez que les espaces de travail liés augmenteront le temps de traitement",
      "linked-workspace": "Aucun espace lié",
      manage: "Gérer les Espaces",
      name: "Nom",
      slug: "Slug",
      date: "Date d'Ajout",
      workspaces: "Espaces",
      search: "Rechercher un espace",
      "no-workspace": "Aucun espace trouvé",
      select: "Tout Sélectionner",
      unselect: "Désélectionner",
      save: "Enregistrer",
    },
  },

  // General Appearance
  general: {
    vector: {
      title: "Nombre de vecteurs",
      description:
        "Nombre total de vecteurs dans votre base de données vectorielle.",
      vectors: "Nombre de vecteurs",
    },
    names: {
      description:
        "Cela ne changera que le nom d'affichage de votre espace de travail.",
    },
    message: {
      title: "Messages de chat suggérés",
      description:
        "Personnalisez les messages qui seront suggérés aux utilisateurs de votre espace de travail.",
      add: "Ajouter un nouveau message",
      save: "Enregistrer les messages",
      heading: "Expliquez-moi",
      body: "les avantages de la plateforme",
      message: "Message",
      "new-heading": "Titre",
    },
    pfp: {
      title: "Image de profil de l'assistant",
      description:
        "Personnalisez l'image de profil de l'assistant pour cet espace de travail.",
      image: "Image de l'espace de travail",
      remove: "Supprimer l'image de l'espace de travail",
    },
    delete: {
      delete: "Supprimer l'espace de travail",
      deleting: "Suppression de l'espace de travail...",
      "confirm-start":
        "Vous êtes sur le point de supprimer l'intégralité de votre",
      "confirm-end":
        "espace de travail. Cela supprimera toutes les incorporations vectorielles dans votre base de données vectorielle. \n \n Les fichiers sources d'origine resteront intacts. Cette action est irréversible.",
    },
  },

  // Chat Settings
  chat: {
    llm: {
      title: "Fournisseur de LLM de l'espace de travail",
      description:
        "Le fournisseur et le modèle de LLM spécifiques qui seront utilisés pour cet espace de travail. Par défaut, il utilise le fournisseur et les paramètres LLM du système.",
      search: "Rechercher tous les fournisseurs de LLM",
    },
    model: {
      title: "Modèle de chat de l'espace de travail",
      description:
        "Le modèle de chat spécifique qui sera utilisé pour cet espace de travail. Si vide, utilisera la préférence LLM du système.",
      wait: "-- en attente des modèles --",
    },
    mode: {
      title: "Mode de chat",
      chat: {
        title: "Chat",
        "desc-start":
          "fournira des réponses avec les connaissances générales du LLM",
        and: "et",
        "desc-end": "le contexte du document trouvé.",
      },
      query: {
        title: "Interrogation",
        "desc-start": "fournira des réponses",
        only: "seulement",
        "desc-end": "si un contexte de document est trouvé.",
      },
    },
    history: {
      title: "Historique des chats",
      "desc-start":
        "Le nombre de chats précédents qui seront inclus dans la mémoire à court terme de la réponse.",
      recommend: "Recommander 20. ",
      "desc-end":
        "Tout ce qui dépasse 45 est susceptible de conduire à des échecs de chat continus en fonction de la taille du message.",
    },
    prompt: {
      title: "Prompt",
      description:
        "Le prompt qui sera utilisé sur cet espace de travail. Définissez le contexte et les instructions pour que l'IA génère une réponse. Vous devez fournir un prompt soigneusement élaboré pour que l'IA puisse générer une réponse pertinente et précise.",
    },
    refusal: {
      title: "Réponse de refus en mode interrogation",
      "desc-start": "En mode",
      query: "interrogation",
      "desc-end":
        ", vous pouvez souhaiter retourner une réponse de refus personnalisée lorsque aucun contexte n'est trouvé.",
    },
    temperature: {
      title: "Température du LLM",
      "desc-start":
        'Ce paramètre contrôle à quel point les réponses de votre LLM seront "créatives".',
      "desc-end":
        "Plus le nombre est élevé, plus il est créatif. Pour certains modèles, cela peut conduire à des réponses incohérentes si le réglage est trop élevé.",
      hint: "La plupart des LLM ont diverses plages acceptables de valeurs valides. Consultez votre fournisseur de LLM pour ces informations.",
    },
    max_tokens: {
      title: "Jetons de connexion maximum par utilisateur",
      desc: "Définissez le nombre maximum de jetons d'authentification actifs que chaque utilisateur peut avoir à la fois. Lorsque ce nombre est dépassé, les jetons plus anciens seront automatiquement supprimés.",
      label: "Jetons maximum",
      help: "La valeur doit être supérieure à 0",
    },
    "dynamic-pdr": {
      title: "PDR Dynamique de l'Espace de Travail",
      description:
        "Activer ou désactiver le PDR Dynamique pour cet espace de travail.",
    },
  },

  // Vector Database
  "vector-workspace": {
    identifier: "Identifiant de la base de données vectorielle",
    snippets: {
      title: "Nombre maximum de fragments de contexte",
      description:
        "Ce paramètre contrôle le nombre maximum de fragments de contexte qui seront envoyés au LLM pour chaque chat ou interrogation.",
      recommend: "Recommandé: 4",
    },
    doc: {
      title: "Seuil de similarité des documents",
      description:
        "Le score de similarité minimum requis pour qu'une source soit considérée comme liée au chat. Plus le nombre est élevé, plus la source doit être similaire au chat.",
      zero: "Aucune restriction",
      low: "Faible (score de similarité ≥ .25)",
      medium: "Moyenne (score de similarité ≥ .50)",
      high: "Élevée (score de similarité ≥ .75)",
    },
    reset: {
      reset: "Réinitialiser la base de données vectorielle",
      resetting: "Effacement des vecteurs...",
      confirm:
        "Vous êtes sur le point de réinitialiser la base de données vectorielle de cet espace de travail. Cela supprimera toutes les incorporations vectorielles actuellement incorporées. \n \n Les fichiers sources d'origine resteront intacts. Cette action est irréversible.",
      error:
        "La base de données vectorielle de l'espace de travail n'a pas pu être réinitialisée !",
      success:
        "La base de données vectorielle de l'espace de travail a été réinitialisée !",
    },
  },

  // Agent Configuration
  agent: {
    "performance-warning":
      "La performance des LLM qui ne prennent pas explicitement en charge l'appel d'outils dépend fortement des capacités et de la précision du modèle. Certaines capacités peuvent être limitées ou non fonctionnelles.",
    provider: {
      title: "Fournisseur de LLM de l'agent de l'espace de travail",
      description:
        "Le fournisseur et le modèle de LLM spécifiques qui seront utilisés pour l'agent de cet espace de travail.",
    },
    mode: {
      chat: {
        title: "Modèle de chat de l'agent de l'espace de travail",
        description:
          "Le modèle de chat spécifique qui sera utilisé pour l'agent de cet espace de travail.",
      },
      title: "Modèle de l'agent de l'espace de travail",
      description:
        "Le modèle de LLM spécifique qui sera utilisé pour l'agent de cet espace de travail.",
      wait: "-- en attente des modèles --",
    },

    skill: {
      title: "Compétences de l'agent par défaut",
      description:
        "Améliorez les capacités naturelles de l'agent par défaut avec ces compétences pré-construites. Cette configuration s'applique à tous les espaces de travail.",
      rag: {
        title: "RAG & mémoire à long terme",
        description:
          "Permettre à l'agent de tirer parti de vos documents locaux pour répondre à une requête ou demander à l'agent de ''se souvenir'' de morceaux de contenu pour une récupération de mémoire à long terme.",
      },
      configure: {
        title: "Configurer les compétences de l'agent",
        description:
          "Personnalisez et améliorez les capacités par défaut de l'agent en activant ou en désactivant des compétences spécifiques. Ces paramètres seront appliqués à tous les espaces de travail.",
      },
      view: {
        title: "Voir & résumer les documents",
        description:
          "Permettre à l'agent de lister et résumer le contenu des fichiers de l'espace de travail actuellement incorporés.",
      },
      scrape: {
        title: "Récupérer le contenu des sites web",
        description:
          "Permettre à l'agent de visiter et de récupérer le contenu des sites web.",
      },
      generate: {
        title: "Générer des graphiques",
        description:
          "Permettre à l'agent par défaut de générer divers types de graphiques à partir des données fournies ou données dans le chat.",
      },
      save: {
        title: "Générer & enregistrer des fichiers dans le navigateur",
        description:
          "Permettre à l'agent par défaut de générer et d'écrire dans des fichiers qui peuvent être enregistrés et téléchargés dans votre navigateur.",
      },
      web: {
        title: "Recherche et navigation sur le web en direct",
        "desc-start":
          "Permettre à votre agent de rechercher sur le web pour répondre à vos questions en se connectant à un fournisseur de recherche web (SERP).",
        "desc-end":
          "La recherche web pendant les sessions d'agent ne fonctionnera pas tant que ceci n'est pas configuré.",
      },
    },
  },

  // Workspace Chats
  recorded: {
    title: "Chats de l'espace de travail",
    description:
      "Ce sont tous les chats et messages enregistrés qui ont été envoyés par les utilisateurs classés par date de création.",
    export: "Exporter",
    table: {
      id: "Id",
      by: "Envoyé par",
      workspace: "Espace de travail",
      prompt: "Prompt",
      response: "Réponse",
      at: "Envoyé à",
    },
    "clear-chats": "Effacer les Conversations",
    "confirm-clear-chats":
      "Êtes-vous sûr de vouloir effacer tous les chats ?\n\nCette action est irréversible.",
    "fine-tune-modal": "Commander un modèle Fine-Tune",
    "confirm-delete.chat":
      "Êtes-vous sûr de vouloir supprimer ce chat ?\n\nCette action est irréversible.",
    next: "Page suivante",
    previous: "Page précédente",
  },

  // Appearance
  appearance: {
    title: "Apparence",
    description:
      "Personnalisez les paramètres d'apparence de votre plateforme.",
    logo: {
      title: "Personnaliser le logo",
      description:
        "Téléchargez votre logo personnalisé pour rendre votre chatbot unique.",
      add: "Ajouter un logo personnalisé",
      recommended: "Taille recommandée : 800 x 200",
      remove: "Supprimer",
    },
    logoDark: {
      title: "Personnaliser le logo pour le mode sombre",
      description: "Téléchargez votre logo personnalisé pour le mode sombre.",
    },
    message: {
      title: "Personnaliser les messages",
      description:
        "Personnalisez les messages automatiques affichés à vos utilisateurs.",
      new: "Nouveau",
      system: "système",
      user: "utilisateur",
      message: "message",
      assistant: "Assistant de Chat",
      "double-click": "Double cliquez pour modifier...",
      save: "Enregistrer les messages",
    },
    icons: {
      title: "Icônes de pied de page personnalisées",
      description:
        "Personnalisez les icônes de pied de page affichées en bas de la barre latérale.",
      icon: "Icône",
      link: "Lien",
    },
    display: {
      title: "Langue d'affichage",
      description: "Sélectionnez la langue préférée.",
    },
    color: {
      title: "Couleurs personnalisées",
      "desc-start": "Personnaliser les",
      "desc-mid": "*couleur de fond, *couleur principale",
      "desc-and": "et",
      "desc-end": "*couleur du texte",
      red: "Rouge",
      gray: "Gris",
      foynet: "Foynet",
      brown: "Marron",
      green: "Vert",
      yellow: "Jaune",
      cyan: "Cyan",
      magenta: "Magenta",
      orange: "TenderFlow",
      purple: "Violet",
      navy: "Bleu Marine",
      black: "Noir",
    },
    login: {
      title: "Texte de connexion personnalisé",
      description:
        "Personnalisez le texte du paragraphe affiché sur la page de connexion.",
      placeholder: "Veuillez contacter l'administrateur du système",
      website: {
        title: "Personnaliser le site Web de l'application",
        description: "Personnalisez l'URL du site Web de votre application.",
        toggle: "Afficher le lien du site Web",
      },
      validation: {
        invalidURL: "Veuillez entrer une URL valide.",
      },
    },
    siteSettings: {
      title: "Paramètres du site personnalisés",
      description:
        "Modifiez le contenu de l'onglet du navigateur pour la personnalisation et le branding.",
      tabTitle: "Titre de l'onglet",
      tabDescription:
        "Définissez un titre d'onglet personnalisé lorsque l'application est ouverte dans un navigateur.",
      tabIcon: "Favicon de l'onglet",
      fabIconUrl:
        "Définissez une URL vers une image à utiliser pour votre favicon.",
      placeholder: "URL de votre image",
    },
    appName: {
      title: "Nom de l'application personnalisé",
      description:
        "Définissez un nom d'application personnalisé qui s'affiche sur la page de connexion.",
    },
    customTab: {
      title: "Personnaliser le nom des onglets",
      tab1: "Onglet 1",
      tab2: "Onglet 2",
    },
  },

  // API Keys
  api: {
    title: "Clés API",
    description:
      "Les clés API permettent au titulaire d'accéder et de gérer cette d'instance de manière programmatique.",
    link: "Lire la documentation de l'API",
    generate: "Générer une nouvelle clé API",
    table: {
      key: "Clé API",
      by: "Créé par",
      created: "Créé",
    },
    new: {
      title: "Créer une nouvelle clé API",
      description:
        "Une fois créée, la clé API peut être utilisée pour accéder et configurer cette instance de manière programmatique.",
      doc: "Lire la documentation de l'API",
      cancel: "Annuler",
      "create-api": "Créer une clé API",
    },
  },

  llm: {
    title: "Préférence LLM",
    description:
      "Ces informations d'identification et paramètres concernent votre fournisseur de chat et d'intégration LLM préféré. Il est important que ces clés soient à jour et correctes, sinon le système ne fonctionnera pas correctement.",
    provider: "Fournisseur de LLM",
    "none-selected": "Aucun sélectionné",
    "select-llm": "Vous devez sélectionner un LLM",
    "search-llm": "Rechercher tous les fournisseurs de LLM",
    "prompt-upgrade": {
      title: "Fournisseur LLM d'amélioration de prompt",
      description:
        "Le fournisseur LLM spécifique et le modèle qui seront utilisés pour améliorer les prompts utilisateur. Par défaut, il utilise le fournisseur LLM système et ses paramètres.",
      search:
        "Rechercher les fournisseurs LLM disponibles pour cette fonctionnalité",
    },
  },

  "llm-provider": {
    openai: "L'option standard pour la plupart des usages non commerciaux.",
    azure: "L'option entreprise d'OpenAI hébergée sur les services Azure.",
    anthropic: "Un assistant IA amical hébergé par Anthropic.",
    gemini: "Le plus grand et le plus capable modèle d'IA de Google",
    huggingface:
      "Accédez à plus de 150 000 LLM open-source et à la communauté mondiale de l'IA",
    ollama: "Exécutez des LLM localement sur votre propre machine.",
    lmstudio:
      "Découvrez, téléchargez et exécutez des milliers de LLM de pointe en quelques clics.",
    localai: "Exécutez des LLM localement sur votre propre machine.",
    togetherai: "Exécutez des modèles open source de Together AI.",
    mistral: "Exécutez des modèles open source de Mistral AI.",
    perplexityai:
      "Exécutez des modèles puissants et connectés à Internet hébergés par Perplexity AI.",
    openrouter: "Une interface unifiée pour les LLM.",
    groq: "L'inférence LLM la plus rapide disponible pour les applications IA en temps réel.",
    koboldcpp: "Exécutez des LLM locaux en utilisant koboldcpp.",
    oobabooga:
      "Exécutez des LLM locaux en utilisant l'interface utilisateur Text Generation Web de Oobabooga.",
    cohere: "Exécutez les puissants modèles Command de Cohere.",
    lite: "Exécutez le proxy OpenAI compatible de LiteLLM pour divers LLM.",
    "generic-openai":
      "Connectez-vous à n'importe quel service compatible OpenAi via une configuration personnalisée",
    native:
      "Utilisez un modèle Llama personnalisé téléchargé pour discuter sur cette instance.",
  },

  // audio preference
  audio: {
    title: "Préférences de Synthèse Vocale",
    provider: "Fournisseur",
    "desc-speech":
      "Ici, vous pouvez spécifier le type de fournisseurs de synthèse vocale et de reconnaissance vocale que vous souhaitez utiliser dans votre expérience de plateforme. Par défaut, nous utilisons le support intégré du navigateur pour ces services, mais vous pouvez en utiliser d'autres.",
    "title-text": "Préférences de Synthèse Vocale",
    "desc-text":
      "Ici, vous pouvez spécifier le type de fournisseurs de synthèse vocale que vous souhaitez utiliser dans votre expérience de plateforme. Par défaut, nous utilisons le support intégré du navigateur pour ces services, mais vous pouvez en utiliser d'autres.",
    "desc-config": "Aucune configuration n'est nécessaire pour ce fournisseur.",
    "placeholder-stt": "Rechercher des fournisseurs de reconnaissance vocale",
    "placeholder-tts": "Rechercher des fournisseurs de synthèse vocale",
    "native-stt":
      "Utilise le service STT intégré de votre navigateur s'il est pris en charge.",
    "native-tts":
      "Utilise le service TTS intégré de votre navigateur s'il est pris en charge.",
    openai: "Utiliser les voix de synthèse vocale d'OpenAI.",
    elevenlabs:
      "Utiliser les voix et la technologie de synthèse vocale d'ElevenLabs.",
  },

  transcription: {
    title: "Préférence du modèle de transcription",
    description:
      "Ce sont les informations d'identification et les paramètres de votre fournisseur de modèle de transcription préféré. Il est important que ces clés soient actuelles et correctes.",
    provider: "Fournisseur de transcription",
    "warn-start":
      "L'utilisation du modèle whisper local sur des machines avec une RAM ou un CPU limité peut bloquer la plateforme lors du traitement des fichiers multimédias.",
    "warn-recommend":
      "Nous recommandons au moins 2 Go de RAM et des fichiers téléchargés <10 Mo.",
    "warn-end":
      "Le modèle intégré se téléchargera automatiquement à la première utilisation.",
    "search-audio": "Rechercher des fournisseurs de transcription audio",
  },

  embedding: {
    title: "Préférence d'incorporation",
    "desc-start":
      "Lors de l'utilisation d'un LLM qui ne prend pas en charge nativement un moteur d'incorporation, vous devrez peut-être spécifier des informations d'identification supplémentaires pour l'incorporation de texte.",
    "desc-end":
      "L'incorporation est le processus de transformation du texte en vecteurs. Ces informations d'identification sont nécessaires pour transformer vos fichiers et vos prompts en un format notre plateforme peut utiliser pour traiter.",
    provider: {
      title: "Fournisseur d'incorporation",
      description:
        "Aucune configuration n'est nécessaire lors de l'utilisation du moteur d'incorporation natif de la plateforme.",
      "search-embed": "Rechercher tous les fournisseurs d'incorporation",
    },
    workspace: {
      title: "Préférence d'Intégration de l'Espace de Travail",
      description:
        "Le fournisseur d'intégration et le modèle spécifiques qui seront utilisés pour cet espace de travail. Par défaut, il utilise le fournisseur et les paramètres d'intégration du système.",
      "multi-model":
        "Le support multi-modèles n'est pas encore pris en charge pour ce fournisseur.",
      "workspace-use": "Cet espace de travail utilisera",
      "model-set": "le modèle défini pour le système.",
      embedding: "Modèle d'Intégration de l'Espace de Travail",
      model:
        "Le modèle d'intégration spécifique qui sera utilisé pour cet espace de travail. S'il est vide, il utilisera la préférence d'intégration du système.",
      wait: "-- en attente des modèles --",
      setup: "Configurer",
      use: "Pour utiliser",
      "need-setup":
        "en tant qu'intégrateur pour cet espace de travail, vous devez d'abord le configurer.",
      cancel: "Annuler",
      save: "Enregistrer",
      settings: "Paramètres",
      search: "Rechercher tous les fournisseurs d'intégration",
      "need-llm":
        "en tant que LLM de cet espace de travail, vous devez d'abord le configurer.",
    },
  },

  text: {
    title: "Préférences de fractionnement & Division du texte",
    "desc-start":
      "Parfois, vous voudrez peut-être modifier la manière par défaut dont les nouveaux documents sont fractionnés et divisés avant d'être insérés dans votre base de données vectorielle.",
    "desc-end":
      "Vous ne devriez modifier ce paramètre que si vous comprenez comment fonctionne le fractionnement du texte et ses effets secondaires.",
    "warn-start": "Les modifications ici ne s'appliqueront qu'aux",
    "warn-center": "nouveaux documents incorporés",
    "warn-end": ", pas aux documents existants.",
    size: {
      title: "Taille des fragments de texte",
      description:
        "C'est la longueur maximale des caractères qui peuvent être présents dans un seul vecteur.",
      recommend: "La longueur maximale du modèle d'incorporation est de",
    },

    overlap: {
      title: "Chevauchement des fragments de texte",
      description:
        "C'est le chevauchement maximal de caractères qui se produit lors de la division entre deux fragments de texte adjacents.",
    },
  },

  // Vector Database
  vector: {
    title: "Base de données vectorielle",
    description:
      "Ce sont les informations d'identification et les paramètres de votre instance. Il est important que ces clés soient actuelles et correctes.",
    provider: {
      title: "Fournisseur de base de données vectorielle",
      description: "Aucune configuration n'est nécessaire pour LanceDB.",
      "search-db":
        "Rechercher tous les fournisseurs de base de données vectorielle",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Widgets de chat intégrables",
    description:
      "Les widgets de chat intégrables sont des interfaces de chat publiques liées à un seul espace de travail. Ils vous permettent de créer des espaces de travail que vous pouvez publier dans le monde entier.",
    create: "Créer une intégration",
    table: {
      workspace: "Espace de travail",
      chats: "Chats envoyés",
      Active: "Domaines actifs",
    },
  },

  "embed-chats": {
    title: "Chats intégrés",
    export: "Export",
    description:
      "Ce sont tous les chats et messages enregistrés à partir de toute intégration que vous avez publiée.",
    table: {
      embed: "Intégrer",
      sender: "Expéditeur",
      message: "Message",
      response: "Réponse",
      at: "Envoyé à",
    },
  },

  multi: {
    title: "Mode multi-utilisateur",
    description:
      "Configurez votre instance pour prendre en charge votre équipe en activant le mode multi-utilisateur.",
    enable: {
      "is-enable": "Le mode multi-utilisateur est activé",
      enable: "Activer le mode multi-utilisateur",
      description:
        "Par défaut, vous serez le seul administrateur. En tant qu'administrateur, vous devrez créer des comptes pour tous les nouveaux utilisateurs ou administrateurs. Ne perdez pas votre mot de passe, car seul un utilisateur administrateur peut réinitialiser les mots de passe.",
      username: "Adresse e-mail du compte administrateur",
      password: "Mot de passe du compte administrateur",
    },
    password: {
      title: "Protection par mot de passe",
      description:
        "Protégez votre instance avec un mot de passe. Si vous l'oubliez, il n'y a pas de méthode de récupération, alors assurez-vous de sauvegarder ce mot de passe.",
    },
    instance: {
      title: "Protéger l'instance par mot de passe",
      description:
        "Par défaut, vous serez le seul administrateur. En tant qu'administrateur, vous devrez créer des comptes pour tous les nouveaux utilisateurs ou administrateurs. Ne perdez pas votre mot de passe, car seul un utilisateur administrateur peut réinitialiser les mots de passe.",
      password: "Mot de passe de l'instance",
    },
  },

  // Event Logs
  event: {
    title: "Journaux d'événements",
    description:
      "Voir toutes les actions et événements se produisant sur cette instance pour surveillance.",
    clear: "Effacer les journaux d'événements",
    table: {
      type: "Type d'événement",
      user: "Utilisateur",
      occurred: "Survenu à",
    },
  },

  // Privacy & Data-Handling
  privacy: {
    title: "Confidentialité & Gestion des données",
    description:
      "Voici votre configuration pour la manière dont les fournisseurs tiers connectés et notre plateforme gèrent vos données.",
    llm: "Sélection de LLM",
    embedding: "Préférence d'incorporation",
    vector: "Base de données vectorielle",
    anonymous: "Télémétrie anonyme activée",
    "desc-event":
      "Tous les événements n'enregistrent pas l'adresse IP et contiennent",
    "desc-id": "aucune identification",
    "desc-cont":
      "contenu, paramètres, chats ou autres informations non basées sur l'utilisation. Pour voir la liste des balises d'événements collectées, vous pouvez consulter",
    "desc-git": "Github ici",
    "desc-end":
      "En tant que projet open-source, nous respectons votre droit à la confidentialité. Nous nous engageons à construire la meilleure solution pour intégrer l'IA et les documents de manière privée et sécurisée. Si vous décidez de désactiver la télémétrie, nous vous demandons simplement de nous envoyer des retours et des idées pour que nous puissions continuer à améliorer la plateforme pour vous",
  },
  "default-chat": {
    welcome: "Bienvenue à IST Legal.",
    "choose-legal": "Choisissez une zone légale à gauche.",
  },
  invites: {
    title: "Invitations",
    description:
      "Créez des liens d'invitation pour les personnes de votre organisation afin qu'elles puissent accepter et s'inscrire. Les invitations ne peuvent être utilisées que par un seul utilisateur.",
    link: "Créer un lien d'invitation",
    accept: "Accepté par",
    "created-by": "Créé par",
    created: "Créé",
    new: {
      title: "Créer une nouvelle invitation",
      "desc-start":
        "Après création, vous pourrez copier l'invitation et l'envoyer à un nouvel utilisateur où il pourra créer un compte en tant que",
      "desc-mid": "par défaut",
      "desc-end":
        "rôle et être automatiquement ajouté aux espaces de travail sélectionnés.",
      "auto-add": "Ajouter automatiquement l'invité aux espaces de travail",
      "desc-add":
        "Vous pouvez éventuellement attribuer automatiquement l'utilisateur aux espaces de travail ci-dessous en les sélectionnant. Par défaut, l'utilisateur n'aura aucun espace de travail visible. Vous pouvez attribuer des espaces de travail plus tard après acceptation de l'invitation.",
      cancel: "Annuler",
      "create-invite": "Créer l'invitation",
      error: "Erreur : ",
    },
    "link-copied": "Lien d'invitation copié",
    "copy-link": "Copier le lien d'invitation",
    "delete-invite-confirmation":
      "Êtes-vous sûr de vouloir désactiver cette invitation ?\nAprès cela, elle ne sera plus utilisable.\n\nCette action est irréversible.",
    status: {
      label: "Statut",
      pending: "En attente",
      disabled: "Désactivé",
      claimed: "Accepté",
    },
  },

  "user-menu": {
    edit: "Modifier le compte",
    profile: "Photo de profil",
    size: "800 x 800",
    "remove-profile": "Supprimer la photo de profil",
    username: "Adresse e-mail",
    "username-placeholder": "Entrez votre adresse e-mail",
    "new-password": "Nouveau mot de passe",
    "new-password-placeholder": "nouveau mot de passe",
    cancel: "Annuler",
    update: "Mettre à jour le compte",
    language: "Langue préférée",
    email: "Adresse e-mail",
    "email-placeholder": "Entrez votre adresse e-mail",
  },

  "user-setting": {
    description:
      "Ce sont tous les comptes qui ont un compte sur cette instance. La suppression d'un compte supprimera instantanément leur accès à cette instance.",
    "add-user": "Ajouter un utilisateur",
    username: "Adresse e-mail",
    role: "Rôle",
    default: "Par défaut",
    manager: "Gestionnaire",
    admin: "Administrateur",
    "date-added": "Date d'ajout",
    edit: "Modifier : ",
    "new-password": "Nouveau mot de passe",
    "password-rule": "Le mot de passe doit contenir au moins 8 caractères.",
    "update-user": "Mettre à jour l'utilisateur",
    placeholder: "Entrez l'adresse e-mail",
    cancel: "Annuler",
    "remove-user": "Supprimer l'utilisateur",
    "remove-user-title": "Supprimer l'utilisateur",
    "remove-user-confirmation":
      "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
    error: "Erreur : ",
    "all-domains": "Tous les domaines",
    "other-users": "Autres utilisateurs (Sans domaine)",
  },

  sidebar: {
    thread: {
      "load-thread": "chargement des fils....",
      "starting-thread": "Démarrage du fil...",
      thread: "Nouveau fil",
      delete: "Supprimer la sélection",
      rename: "Renommer",
      "delete-thread": "Supprimer le fil",
      deleted: "supprimé",
      default: "défaut",
      "empty-thread": "Fil de discussion",
      "rename-message":
        "Comment souhaitez-vous renommer ce fil de discussion ?",
      "delete-message":
        "Êtes-vous sûr de vouloir supprimer ce fil de discussion ? Tous ses chats seront supprimés. Vous ne pouvez pas annuler cette action.",
    },
  },

  embeder: {
    allm: "Utilisez le fournisseur d'incorporation intégré. Aucune configuration!",
    openai: "L'option standard pour la plupart des usages non commerciaux.",
    azure: "L'option entreprise d'OpenAI hébergée sur les services Azure.",
    localai:
      "Exécutez des modèles d'incorporation localement sur votre propre machine.",
    ollama:
      "Exécutez des modèles d'incorporation localement sur votre propre machine.",
    lmstudio:
      "Découvrez, téléchargez et exécutez des milliers de modèles LLM de pointe en quelques clics.",
    cohere: "Exécutez des modèles d'incorporation puissants de Cohere.",
    voyageai: "Exécutez des modèles d'incorporation puissants de Voyage AI.",
  },

  vectordb: {
    lancedb:
      "Base de données vectorielle 100 % locale qui fonctionne sur la même instance notre plateforme",
    chroma:
      "Base de données vectorielle open source que vous pouvez héberger vous-même ou sur le cloud.",
    pinecone:
      "Base de données vectorielle 100 % cloud pour les cas d'utilisation en entreprise.",
    zilliz:
      "Base de données vectorielle hébergée sur le cloud, conçue pour les entreprises avec conformité SOC 2.",
    qdrant:
      "Base de données vectorielle open source locale et distribuée sur le cloud.",
    weaviate:
      "Base de données vectorielle multi-modale open source, locale et hébergée sur le cloud.",
    milvus: "Open-source, hautement évolutif et extrêmement rapide.",
    astra: "Recherche vectorielle pour GenAI dans le monde réel.",
  },

  system: {
    title: "Préférences du système",
    "desc-start":
      "Ce sont les paramètres et configurations généraux de votre instance.",
    user: "Les utilisateurs peuvent supprimer des espaces de travail",
    "desc-delete":
      "Permettre aux utilisateurs non-administrateurs de supprimer des espaces de travail auxquels ils appartiennent. Cela supprimerait l'espace de travail pour tout le monde.",
    limit: {
      title: "Limiter les messages par utilisateur par jour",
      "desc-limit":
        "Restreindre les utilisateurs non-administrateurs à un nombre de requêtes ou de chats réussis dans une fenêtre de 24 heures. Activez ceci pour empêcher les utilisateurs d'augmenter les coûts OpenAI.",
      "per-day": "Limite de messages par jour",
      label: "Limiter les messages : ",
    },
    state: {
      enabled: "Activé",
      disabled: "Désactivé",
    },
    "source-highlighting": {
      title: "Activer / Désactiver la mise en évidence des sources",
      description:
        "Masquer ou afficher la mise en évidence des sources aux utilisateurs.",
      label: "Citation : ",
    },
    save: "Enregistrer les modifications",
  },

  support: {
    title: "Email de Support",
    description:
      "Définissez l'adresse email de support qui apparaît dans le menu utilisateur lors de la connexion à cette instance.",
    clear: "Effacer",
    save: "Enregistrer",
  },
  "public-mode": {
    enable: "Activer le Mode Public-Utilisateur",
    enabled: "Le Mode Public-Utilisateur est Activé",
  },

  button: {
    delete: "Supprimer",
    edit: "Modifier",
    suspend: "Suspendre",
    unsuspend: "Réactiver",
    accept: "Accepter",
    decline: "Décliner",
    save: "Sauvegarder",
    "flush-vector-caches": "Vider les caches vectoriels",
    saving: "Enregistrement",
  },

  "new-user": {
    title: "Ajouter un utilisateur à l'instance",
    username: "Adresse e-mail",
    "username-ph": "Entrez l'adresse e-mail de l'utilisateur",
    password: "Mot de passe",
    "password-ph": "Mot de passe initial de l'utilisateur",
    role: "Rôle",
    default: "Par défaut",
    manager: "Responsable",
    admin: "Administrateur",
    description:
      "Après avoir créé un utilisateur, il devra se connecter avec son mot de passe initial pour obtenir l'accès.",
    cancel: "Annuler",
    "add-User": "Ajouter un utilisateur",
    error: "Erreur : ",
    "invalid-email": "Veuillez saisir une adresse e-mail valide.",
    permissions: {
      title: "Autorisations",
      default: [
        "Peut seulement envoyer des chats avec les espaces de travail auxquels ils ont été ajoutés par un administrateur ou des gestionnaires.",
        "Ne peut modifier aucun paramètre.",
      ],
      manager: [
        "Peut voir, créer et supprimer des espaces de travail et modifier les paramètres spécifiques aux espaces de travail.",
        "Peut créer, mettre à jour et inviter de nouveaux utilisateurs à l'instance.",
        "Ne peut pas modifier LLM, vectorDB, l'intégration, ou d'autres connexions.",
      ],
      admin: [
        "Privilège de niveau utilisateur le plus élevé.",
        "Peut voir et faire tout sur le système.",
      ],
    },
  },

  "new-embed": {
    title: "Créer un nouvel embed pour l'espace de travail",
    error: "Erreur : ",
    "desc-start":
      "Après avoir créé un embed, un lien vous sera fourni que vous pourrez publier sur votre site web avec un simple",
    script: "script",
    tag: "tag.",
    cancel: "Annuler",
    "create-embed": "Créer un embed",
    workspace: "Espace de travail",
    "desc-workspace":
      "Ceci est l'espace de travail sur lequel votre fenêtre de chat sera basée. Tous les paramètres par défaut seront hérités de l'espace de travail à moins qu'ils ne soient remplacés par cette configuration.",
    "allowed-chat": "Méthode de chat autorisée",
    "desc-query":
      "Définissez comment votre chatbot doit fonctionner. Query signifie qu'il ne répondra que si un document aide à répondre à la requête.",
    "desc-chat":
      "Chat ouvre le chat à des questions générales et peut répondre à des requêtes totalement non liées à votre espace de travail.",
    "desc-response":
      "Chat : Répondre à toutes les questions, quel que soit le contexte",
    "query-response":
      "Query : Répondre uniquement aux chats liés aux documents dans l'espace de travail",
    restrict: "Restreindre les demandes de domaines",
    filter:
      "Ce filtre bloquera toutes les demandes provenant d'un domaine autre que la liste ci-dessous.",
    "use-embed":
      "Laisser ce champ vide signifie que n'importe qui peut utiliser votre embed sur n'importe quel site.",
    "max-chats": "Chats max par jour",
    "limit-chats":
      "Limitez le nombre de chats que ce chat intégré peut traiter en une période de 24 heures. Zéro est illimité.",
    "chats-session": "Chats max par session",
    "limit-chats-session":
      "Limitez le nombre de chats qu'un utilisateur de session peut envoyer avec cet embed en une période de 24 heures. Zéro est illimité.",
    "enable-dynamic": "Activer l'utilisation dynamique du modèle",
    "llm-override":
      "Permettre de définir le modèle LLM préféré pour remplacer le paramètre par défaut de l'espace de travail.",
    "llm-temp": "Activer la température LLM dynamique",
    "desc-temp":
      "Permettre de définir la température LLM pour remplacer le paramètre par défaut de l'espace de travail.",
    "prompt-override": "Activer le remplacement de l'invite",
    "desc-override":
      "Permettre de définir l'invite système pour remplacer le paramètre par défaut de l'espace de travail.",
  },

  "show-toast": {
    "recovery-codes": "Codes de récupération copiés dans le presse-papiers",
    "scraping-website": "Extraction du site web - cela peut prendre un moment.",
    "fetching-transcript":
      "Récupération de la transcription de la vidéo YouTube.",
    "updating-workspace": "Mise à jour de l'espace de travail...",
    "flashing-started": "Démarrage du flash...",
    "flashing-success": "Flash terminé avec succès",
    "flashing-error": "Erreur lors du flash : {{error}}",
    "link-uploaded": "Lien téléchargé avec succès",
    "password-reset": "Réinitialisation du mot de passe réussie",
    "invalid-reset": "Jeton de réinitialisation invalide",
    "delete-option": "Le thread n'a pas pu être supprimé !",
    "thread-deleted": "Thread supprimé avec succès !",
    "chat-deleted": "Chat supprimé avec succès !",
    "failed-delete-chat":
      "Erreur lors de la suppression du chat. Veuillez réessayer.",
    "error-deleting-chat":
      "Une erreur s'est produite lors de la suppression de la discussion.",
    "picture-uploaded": "Photo de profil téléchargée.",
    "profile-updated": "Profil mis à jour.",
    "logs-cleared": "Journaux des événements effacés avec succès.",
    "preferences-updated": "Préférences système mises à jour avec succès.",
    "user-created": "Utilisateur créé avec succès.",
    "user-creation-error": "Échec de la création de l'utilisateur : ",
    "user-deleted": "Utilisateur supprimé du système.",
    "workspaces-saved": "Espaces de travail enregistrés avec succès !",
    "failed-workspaces":
      "Échec de l'enregistrement des espaces de travail. Veuillez réessayer.",
    "api-deleted": "Clé API supprimée définitivement",
    "api-copied": "Clé API copiée dans le presse-papiers",
    "appname-updated":
      "Nom personnalisé de l'application mis à jour avec succès.",
    "language-updated": "Langue mise à jour avec succès.",
    "palette-updated": "Palette mise à jour avec succès.",
    "image-uploaded": "Image téléchargée avec succès.",
    "logo-removed": "Image supprimée avec succès.",
    "logo-remove-error": "Erreur lors de la suppression du logo: ",
    "updated-welcome": "Messages de bienvenue mis à jour avec succès.",
    "updated-footer": "Icônes de pied de page mises à jour avec succès.",
    "updated-paragraph":
      "Texte du paragraphe personnalisé mis à jour avec succès.",
    "updated-supportemail": "Email de support mis à jour avec succès.",
    "stt-success":
      "Préférences de reconnaissance vocale enregistrées avec succès.",
    "tts-success": "Préférences de synthèse vocale enregistrées avec succès.",
    "failed-chats-export": "Échec de l'exportation des chats.",
    "chats-exported": "Discussions exportées avec succès sous le nom {{name}}.",
    "cleared-chats": "Tous les chats effacés.",
    "embed-deleted": "Embed supprimé du système.",
    "snippet-copied": "Extrait copié dans le presse-papiers !",
    "embed-updated": "Embed mis à jour avec succès.",
    "embedding-saved": "Préférences d'embedding enregistrées avec succès.",
    "chunking-settings":
      "Paramètres de stratégie de découpage de texte enregistrés.",
    "llm-saved": "Préférences LLM enregistrées avec succès.",
    "llm-saving-error": "Échec de l'enregistrement des paramètres LLM : ",
    "multiuser-enabled": "Mode multi-utilisateur activé avec succès.",
    "publicuser-enabled": "Mode utilisateur public activé avec succès.",
    "publicuser-disabled": "Mode utilisateur public désactivé avec succès.",
    "page-refresh": "Votre page sera actualisée dans quelques secondes.",
    "transcription-saved":
      "Préférences de transcription enregistrées avec succès.",
    "vector-saved":
      "Préférences de base de données vectorielle enregistrées avec succès.",
    "workspace-not-deleted": "L'espace de travail n'a pas pu être supprimé !",
    "maximum-messages": "Maximum de 4 messages autorisés.",
    "users-updated": "Utilisateurs mis à jour avec succès.",
    "vectordb-not-reset":
      "La base de données vectorielle de l'espace de travail n'a pas pu être réinitialisée !",
    "vectordb-reset":
      "La base de données vectorielle de l'espace de travail a été réinitialisée !",
    "linked-workspaces-updated":
      "Les espaces liés ont été mis à jour avec succès.",
    "appname-update-error":
      "Échec de la mise à jour du nom personnalisé de l'application : ",
    "logo-uploaded": "Logo téléchargé avec succès.",
    "logo-upload-error": "Échec du téléchargement du logo : ",
    "update-welcome-error":
      "Échec de la mise à jour des messages de bienvenue :",
    "update-footer-error":
      "Échec de la mise à jour des icônes de pied de page : ",
    "update-paragraph-error":
      "Échec de la mise à jour du texte personnalisé : ",
    "update-supportemail-error":
      "Échec de la mise à jour de l'e-mail de support : ",
    "meta-data-update": "Préférences du site mises à jour !",
    "reset-tab-name-error":
      "Échec de la réinitialisation du nom d'onglet par défaut.",
    "update-tab-name-error": "Échec de la mise à jour des noms d'onglets : ",
    "updated-website": "Paramètres du site web mis à jour avec succès.",
    "update-website-error": "Échec de la mise à jour du lien du site web : ",
    "reset-website-error":
      "Échec de la réinitialisation du lien du site web par défaut.",
    "palette-update-error":
      "Échec de la mise à jour de la palette de couleurs:",
    "citation-state-updated":
      "État des citations mis à jour avec succès. {{citationState}}",
    "citation-state-update-error":
      "Échec de la mise à jour des paramètres de citation",
    "citation-update-error":
      "Erreur lors de la mise à jour des paramètres de citation",
    "message-limit-updated":
      "Préférences de limite de messages mises à jour avec succès.",
    "upgrade-text-error":
      "Erreur lors de la mise à jour du texte. Veuillez réessayer.",
    "invoice-logging-state-updated":
      "Préférences de journalisation des factures mises à jour avec succès.",
    "invoice-logging-state-update-error":
      "Erreur lors de la mise à jour de l'état de journalisation des factures : ",
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Vos conversations ne seront pas utilisées pour l'entraînement",
        "Vos invites et le texte des documents utilisés dans la création de réponses sont visibles par OpenAI",
      ],
    },
    azure: {
      description: [
        "Vos conversations ne seront pas utilisées pour l'entraînement",
        "Votre texte et le texte d'intégration ne sont pas visibles par OpenAI ou Microsoft",
      ],
    },
    anthropic: {
      description: [
        "Vos conversations ne seront pas utilisées pour l'entraînement",
        "Vos invites et le texte des documents utilisés dans la création de réponses sont visibles par Anthropic",
      ],
    },
    gemini: {
      description: [
        "Vos conversations sont anonymisées et utilisées pour l'entraînement",
        "Vos invites et le texte des documents utilisés dans la création de réponses sont visibles par Google",
      ],
    },
    lmstudio: {
      description: [
        "Votre modèle et vos conversations ne sont accessibles que sur le serveur exécutant LMStudio",
      ],
    },
    localai: {
      description: [
        "Votre modèle et vos conversations ne sont accessibles que sur le serveur exécutant LocalAI",
      ],
    },
    ollama: {
      description: [
        "Votre modèle et vos conversations ne sont accessibles que sur la machine exécutant les modèles Ollama",
      ],
    },
    native: {
      description: [
        "Votre modèle et vos conversations ne sont accessibles que sur cette instance notre plateforme",
      ],
    },
    togetherai: {
      description: [
        "Vos conversations ne seront pas utilisées pour l'entraînement",
        "Vos invites et le texte des documents utilisés dans la création de réponses sont visibles par TogetherAI",
      ],
    },
    mistral: {
      description: [
        "Vos invites et le texte des documents utilisés dans la création de réponses sont visibles par Mistral",
      ],
    },
    huggingface: {
      description: [
        "Vos invites et le texte des documents utilisés dans la réponse sont envoyés à votre point de terminaison géré par HuggingFace",
      ],
    },
    perplexity: {
      description: [
        "Vos conversations ne seront pas utilisées pour l'entraînement",
        "Vos invites et le texte des documents utilisés dans la création de réponses sont visibles par Perplexity AI",
      ],
    },
    openrouter: {
      description: [
        "Vos conversations ne seront pas utilisées pour l'entraînement",
        "Vos invites et le texte des documents utilisés dans la création de réponses sont visibles par OpenRouter",
      ],
    },
    groq: {
      description: [
        "Vos conversations ne seront pas utilisées pour l'entraînement",
        "Vos invites et le texte des documents utilisés dans la création de réponses sont visibles par Groq",
      ],
    },
    koboldcpp: {
      description: [
        "Votre modèle et vos conversations ne sont accessibles que sur le serveur exécutant KoboldCPP",
      ],
    },
    textgenwebui: {
      description: [
        "Votre modèle et vos conversations ne sont accessibles que sur le serveur exécutant l'interface utilisateur de génération de texte Oobabooga",
      ],
    },
    "generic-openai": {
      description: [
        "Les données sont partagées conformément aux conditions de service applicables avec votre fournisseur de point de terminaison générique.",
      ],
    },
    cohere: {
      description: [
        "Les données sont partagées conformément aux conditions de service de cohere.com et aux lois sur la confidentialité de vos localités.",
      ],
    },
    litellm: {
      description: [
        "Votre modèle et vos conversations ne sont accessibles que sur le serveur exécutant LiteLLM",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Vos vecteurs et le texte des documents sont stockés sur votre instance Chroma",
        "L'accès à votre instance est géré par vous",
      ],
    },
    pinecone: {
      description: [
        "Vos vecteurs et le texte des documents sont stockés sur les serveurs de Pinecone",
        "L'accès à vos données est géré par Pinecone",
      ],
    },
    qdrant: {
      description: [
        "Vos vecteurs et le texte des documents sont stockés sur votre instance Qdrant (cloud ou auto-hébergée)",
      ],
    },
    weaviate: {
      description: [
        "Vos vecteurs et le texte des documents sont stockés sur votre instance Weaviate (cloud ou auto-hébergée)",
      ],
    },
    milvus: {
      description: [
        "Vos vecteurs et le texte des documents sont stockés sur votre instance Milvus (cloud ou auto-hébergée)",
      ],
    },
    zilliz: {
      description: [
        "Vos vecteurs et le texte des documents sont stockés sur votre cluster cloud Zilliz.",
      ],
    },
    astra: {
      description: [
        "Vos vecteurs et le texte des documents sont stockés sur votre base de données cloud AstraDB.",
      ],
    },
    lancedb: {
      description: [
        "Vos vecteurs et le texte des documents sont stockés de manière privée sur cette instance notre plateforme",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: [
        "Le texte de vos documents est intégré de manière privée sur cette instance notre plateforme",
      ],
    },
    openai: {
      description: [
        "Le texte de vos documents est envoyé aux serveurs OpenAI",
        "Vos documents ne sont pas utilisés pour l'entraînement",
      ],
    },
    azure: {
      description: [
        "Le texte de vos documents est envoyé à votre service Microsoft Azure",
        "Vos documents ne sont pas utilisés pour l'entraînement",
      ],
    },
    localai: {
      description: [
        "Le texte de vos documents est intégré de manière privée sur le serveur exécutant LocalAI",
      ],
    },
    ollama: {
      description: [
        "Le texte de vos documents est intégré de manière privée sur le serveur exécutant Ollama",
      ],
    },
    lmstudio: {
      description: [
        "Le texte de vos documents est intégré de manière privée sur le serveur exécutant LMStudio",
      ],
    },
    cohere: {
      description: [
        "Les données sont partagées conformément aux conditions de service de cohere.com et aux lois sur la confidentialité de vos localités.",
      ],
    },
    voyageai: {
      description: [
        "Les données envoyées aux serveurs Voyage AI sont partagées conformément aux conditions de service de voyageai.com.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Modifier",
    response: "Réponse",
    prompt: "Invite",
    regenerate: "Régénérer la réponse",
    good: "Bonne réponse",
    bad: "Mauvaise réponse",
    copy: "Copier",
    more: "Plus d'actions",
    fork: "Dupliquer",
    delete: "Supprimer",
    cancel: "Annuler",
    save: "Enregistrer & Soumettre",
  },

  citations: {
    show: "Afficher les citations",
    hide: "Masquer les citations",
    chunk: "Morceaux de citations",
    pdr: "Document parent récupéré",
    "pdr-h": "Surlignage du document source",
    referenced: "Référencé",
    times: "fois.",
    citation: "Citation",
    match: "correspondance",
    download:
      "Ce navigateur ne prend pas en charge les PDFs. Veuillez télécharger le PDF pour le visualiser :",
    "download-btn": "Télécharger le PDF",
  },

  "document-drafting": {
    title: "Rédaction de Documents",
    description: "Contrôlez vos paramètres de rédaction de documents.",
    configuration: "Configuration",
    "drafting-model": "Modèle LLM de Rédaction",
    enabled: "La rédaction de documents est activée",
    disabled: "La rédaction de documents est désactivée",
    "enabled-toast": "Rédaction de documents activée",
    "disabled-toast": "Rédaction de documents désactivée",
    "desc-settings":
      "L'administrateur peut modifier les paramètres de rédaction de documents pour tous les utilisateurs.",
    "drafting-llm": "Préférence de LLM de Rédaction",
    saving: "Enregistrement...",
    save: "Enregistrer les modifications",
    "chat-settings": "Paramètres du Chat",
    "drafting-chat-settings":
      "Paramètres du Chat pour la Rédaction de Documents",
    "chat-settings-desc":
      "Contrôlez le comportement de la fonctionnalité de chat pour la rédaction de documents.",
    "drafting-prompt": "Invite du système de rédaction de documents",
    "drafting-prompt-desc":
      "L'invite du système qui sera utilisée dans la rédaction de documents est différente de l'invite du système de questions-réponses juridiques. L'invite du système de rédaction de documents définit le contexte et les instructions pour que l'IA génère une réponse. Vous devez fournir une invite soigneusement conçue pour que l'IA puisse générer une réponse pertinente et précise.",
    "legal-issues-prompt":
      "Quelles questions juridiques se posent dans le contexte donné avec le prompt?",
    "legal-issues-prompt-desc":
      "Entrez l'invite pour les questions juridiques.",
    "memo-prompt": "Fournir une note sur chacun de ces problèmes juridiques.",
    "memo-prompt-desc": "Entrez l'invite pour le mémo.",
    linking: "Lien de document",
    "desc-linkage":
      "Activez l'ajout de contexte juridique supplémentaire en effectuant des recherches vectorielles/PDR sur le mémo.",
  },

  "dd-settings": {
    title: "Paramètres de Rédaction de Documents",
    description:
      "Contrôler les limites de tokens et le comportement des fonctionnalités de rédaction de documents",
    "vector-search": {
      title: "Recherche Vectorielle",
      description:
        "Utiliser la recherche vectorielle pour trouver des documents pertinents",
    },
    "memo-generation": {
      title: "Génération de Mémos",
      description:
        "Générer des mémos sur les questions juridiques pour le contexte de recherche",
    },
    "base-generation": {
      title: "Analyse Juridique de Base",
      description:
        "Générer une analyse juridique initiale à partir des requêtes utilisateur",
    },
    "linked-workspace-impact": {
      title: "Impact des Espaces de Travail Liés",
      description:
        "Ajuster les tokens disponibles en fonction du nombre d'espaces de travail liés",
    },
    "vector-token-limit": {
      title: "Limite de Tokens Vectoriels",
      description:
        "Nombre maximum de tokens par espace de travail lié pour la recherche vectorielle",
    },
    "memo-token-limit": {
      title: "Limite de Tokens des Mémos",
      description:
        "Nombre maximum de tokens pour la génération de mémos juridiques",
    },
    "base-token-limit": {
      title: "Limite de Tokens de Base",
      description:
        "Nombre maximum de tokens pour la récupération du contenu de base",
    },
    "toast-success": "Paramètres mis à jour avec succès",
    "toast-fail": "Échec de la mise à jour des paramètres",
  },

  modale: {
    document: {
      title: "Mes Documents",
      document: "Documents",
      search: "Rechercher un document",
      folder: "Nouveau Dossier",
      name: "Nom",
      empty: "Aucun Document",
      "move-workspace": "Déplacer vers l'espace de travail",
      "doc-processor": "Processeur de Documents Indisponible",
      "processor-offline":
        "Nous ne pouvons pas télécharger vos fichiers pour le moment car le processeur de documents est hors ligne. Veuillez réessayer plus tard.",
      "drag-drop": "Cliquez pour télécharger ou faites glisser et déposez",
      "supported-files":
        "prend en charge les fichiers texte, csv, feuilles de calcul, fichiers audio, et plus encore !",
      "submit-link": "ou soumettez un lien",
      fetch: "Récupérer le site web",
      fetching: "Récupération...",
      "file-desc":
        "Ces fichiers seront téléchargés vers le processeur de documents fonctionnant sur cette instance. Ces fichiers ne sont ni envoyés ni partagés avec un tiers.",
      cost: "*Coût unique pour les embeddings",
      "save-embed": "Enregistrer et Intégrer",
      "loading-message":
        "Cela peut prendre un certain temps pour les documents volumineux",
      "failed-uploads": "Impossible de télécharger les fichiers suivants",
    },
    connectors: {
      title: "Connecteurs de Données",
      search: "Rechercher des connecteurs de données",
      empty: "Aucun connecteur de données trouvé.",
    },
  },
  dataConnectors: {
    github: {
      name: "Dépôt GitHub",
      description: "Importez un dépôt GitHub public ou privé en un seul clic.",
      url: "URL du Dépôt GitHub",
      "collect-url": "URL du dépôt GitHub que vous souhaitez collecter.",
      "access-token": "Jeton d'Accès GitHub",
      optional: "facultatif",
      "rate-limiting": "Jeton d'accès pour éviter la limitation de débit.",
      "desc-picker":
        "Une fois terminé, tous les fichiers seront disponibles pour être intégrés dans les espaces de travail dans le sélecteur de documents.",
      branch: "Branche",
      "branch-desc":
        "Branche à partir de laquelle vous souhaitez collecter des fichiers.",
      "branch-loading": "-- chargement des branches disponibles --",
      "desc-start": "Sans remplir le",
      "desc-token": "Jeton d'Accès GitHub",
      "desc-connector": "ce connecteur de données ne pourra collecter que les",
      "desc-level": "fichiers de niveau supérieur",
      "desc-end":
        "du dépôt en raison des limitations de débit de l'API publique de GitHub.",
      "personal-token":
        "Obtenez un jeton d'accès personnel gratuit avec un compte GitHub ici.",
      without: "Sans un",
      "personal-token-access": "Jeton d'Accès Personnel",
      "desc-api":
        ", l'API GitHub peut limiter le nombre de fichiers pouvant être collectés en raison des limites de débit. Vous pouvez",
      "temp-token": "créer un jeton d'accès temporaire",
      "avoid-issue": "pour éviter ce problème.",
      submit: "Soumettre",
      "collecting-files": "Collecte des fichiers...",
    },
    "youtube-transcript": {
      name: "Transcription YouTube",
      description:
        "Importez la transcription d'une vidéo YouTube entière à partir d'un lien.",
      url: "URL de la Vidéo YouTube",
      "url-video": "URL de la vidéo YouTube que vous souhaitez transcrire.",
      collect: "Collecter la transcription",
      collecting: "Collecte de la transcription...",
      "desc-end":
        "Une fois terminé, la transcription sera disponible pour être intégrée dans les espaces de travail dans le sélecteur de documents.",
    },
    "website-depth": {
      name: "Récupérateur de Liens en Masse",
      description:
        "Récupérez un site Web et ses sous-liens jusqu'à une certaine profondeur.",
      url: "URL du Site Web",
      "url-scrape": "URL du site Web que vous souhaitez récupérer.",
      depth: "Profondeur",
      "child-links":
        "C'est le nombre de sous-liens que le travailleur doit suivre à partir de l'URL d'origine.",
      "max-links": "Liens Maximum",
      "links-scrape": "Nombre maximum de liens à récupérer.",
      scraping: "Récupération du site Web...",
      submit: "Soumettre",
      "desc-scrap":
        "Une fois terminé, toutes les pages récupérées seront disponibles pour être intégrées dans les espaces de travail dans le sélecteur de documents.",
    },
    confluence: {
      name: "Confluence",
      description: "Importez une page Confluence entière en un seul clic.",
      url: "URL de la Page Confluence",
      "url-page": "URL d'une page dans l'espace Confluence.",
      username: "Nom d'Utilisateur Confluence",
      "own-username": "Votre nom d'utilisateur Confluence.",
      token: "Jeton d'Accès Confluence",
      "desc-start":
        "Vous devez fournir un jeton d'accès pour l'authentification. Vous pouvez générer un jeton d'accès",
      here: "ici",
      access: "Jeton d'accès pour l'authentification.",
      collecting: "Collecte des pages...",
      submit: "Soumettre",
      "desc-end":
        "Une fois terminé, toutes les pages seront disponibles pour être intégrées dans les espaces de travail.",
    },
  },

  module: {
    "legal-qa": "Questions-Réponses Juridiques",
    "document-drafting": "Rédaction de Documents",
  },

  "fine-tune": {
    title: "Vous avez suffisamment de données pour un ajustement fin!",
    link: "cliquez pour en savoir plus",
    dismiss: "rejeter",
  },

  mobile: {
    disclaimer:
      "AVERTISSEMENT : Pour une meilleure expérience et un accès complet à toutes les fonctionnalités, veuillez utiliser un ordinateur pour accéder à l'application.",
  },

  "default-settings": {
    title: "Paramètres par Défaut pour Questions/Réponses Juridiques",
    "default-desc":
      "Contrôlez le comportement par défaut des espaces de travail pour les Questions/Réponses Juridiques",
    prompt: "Invite du Système de Questions/Réponses Juridiques",
    "prompt-desc":
      "L'invite par défaut qui sera utilisée dans les Questions/Réponses Juridiques. Définissez le contexte et les instructions pour que l'IA génère une réponse. Vous devez fournir une invite soigneusement élaborée afin que l'IA puisse générer une réponse pertinente et précise.",
    "toast-success": "Invite du système par défaut mise à jour",
    "toast-fail": "Échec de la mise à jour de l'invite du système",
    snippets: {
      title: "Extraits de contexte maximum par défaut",
      description:
        "Ce paramètre contrôle le nombre maximum d'extraits de contexte par défaut qui seront envoyés au LLM pour chaque chat ou requête.",
      recommend: "Recommandé : 10",
    },
    "validation-prompt": {
      title: "Invite de validation",
      description:
        "Ce paramètre contrôle l'invite de validation par défaut qui sera envoyée au LLM pour valider la réponse donnée.",
      placeholder:
        "Veuillez valider la réponse suivante en vérifiant l'exactitude de toutes les références juridiques et citations par rapport au contexte fourni. Énumérez toutes les inexactitudes ou représentations erronées trouvées.",
    },
  },

  "confirm-message": {
    "delete-doc":
      "Êtes-vous sûr de vouloir supprimer ces fichiers et dossiers ?\nCela supprimera les fichiers du système et les supprimera automatiquement de tous les espaces de travail existants.\nCette action est irréversible.",
    "confirm-delete.chat":
      "Êtes-vous sûr de vouloir supprimer ce chat ?\n\nCette action est irréversible.",
  },

  "pdr-settings": {
    title: "Paramètres PDR",
    description:
      "Contrôlez les mécanismes de gestion de la limite de jetons dynamiques pour l'algorithme PDR",
    "desc-end":
      "Vous ne devriez modifier ce paramètre que si vous comprenez comment fonctionne l'algorithme PDR.",
    "pdr-token-limit": "Limite de Jeton PDR",
    "pdr-token-limit-desc":
      "Le nombre maximum de jetons qui seront utilisés pour l'algorithme PDR.",
    "input-prompt-token-limit": "Limite de Jeton de l'Invite",
    "input-prompt-token-limit-desc":
      "Le nombre maximum de jetons qui seront utilisés pour l'invite.",
    "response-token-limit": "Limite de Jeton de Réponse",
    "response-token-limit-desc":
      "Le nombre maximum de jetons qui seront utilisés pour la réponse.",
    "toast-success": "Paramètres PDR mis à jour",
    "toast-fail": "Échec de la mise à jour des paramètres PDR",
    "adjacent-vector-limit": "Limite de Vecteurs Adjoints",
    "adjacent-vector-limit-desc":
      "Le nombre de vecteurs adjoints qui seront utilisés pour l'algorithme PDR.",
  },
  performLegalTask: {
    title: "Effectuer une tâche juridique",
    description:
      "Activez ou désactivez le bouton 'Effectuer une tâche juridique' dans la rédaction de documents.",
    successMessage: "La tâche juridique a été {{status}}",
    failureUpdateMessage:
      "Échec de la mise à jour du paramètre de tâche juridique.",
    errorSubmitting:
      "Erreur lors de l'envoi des paramètres de la tâche juridique.",
  },
  statuses: {
    enabled: "activée",
    disabled: "désactivée",
  },
  "answer-upgrade": {
    title: "Améliorer la Réponse",
    "text-upgrade-prompt":
      "Prompt: Veuillez modifier ce texte pour le rendre plus {{category}}. Ne retournez que la version modifiée de ce texte exact, rien d'autre : {{selectedText}}",
    "category-step": {
      title: "Sélectionner une Catégorie",
      description:
        "Choisissez une catégorie qui correspond le mieux à vos besoins",
      categories: {
        formality: {
          label: "Formalité",
          choices: {
            more_formal: "Rendre plus formel",
            less_formal: "Rendre moins formel",
            more_professional: "Rendre plus professionnel",
            more_casual: "Rendre plus décontracté",
            more_polished: "Rendre plus raffiné",
            more_relaxed: "Rendre plus détendu",
            academic_tone: "Utiliser un ton plus académique",
            conversational_tone: "Utiliser un ton plus conversationnel",
          },
        },
        complexity: {
          label: "Complexité du Langage",
          choices: {
            simplify: "Simplifier le langage",
            more_descriptive: "Ajouter plus de descriptions",
            complex_vocab: "Utiliser un vocabulaire plus complexe",
            simple_vocab: "Utiliser un vocabulaire plus simple",
            technical: "Augmenter l'utilisation du langage technique",
            layman: "Utiliser des termes plus courants",
            add_jargon: "Incorporer du jargon spécifique au domaine",
            avoid_jargon: "Éviter le jargon et utiliser des termes généraux",
            add_rhetorical: "Ajouter plus de questions rhétoriques",
            less_rhetorical: "Utiliser moins de questions rhétoriques",
          },
        },
        structure: {
          label: "Structure des Phrases",
          choices: {
            shorter: "Raccourcir les phrases",
            longer: "Allonger les phrases",
            vary: "Varier la structure des phrases",
            standardize: "Standardiser la structure des phrases",
            more_complex: "Utiliser des phrases plus complexes",
            simpler: "Utiliser des phrases plus simples",
            active_voice: "Augmenter l'utilisation de la voix active",
            passive_voice: "Augmenter l'utilisation de la voix passive",
          },
        },
        figurative: {
          label: "Langage Figuré",
          choices: {
            more_figurative: "Utiliser plus de langage figuré",
            less_figurative: "Réduire l'utilisation du langage figuré",
            metaphors: "Ajouter plus de métaphores et de comparaisons",
            literal: "Utiliser un langage plus littéral",
            more_idioms: "Incorporer plus d'expressions idiomatiques",
            less_idioms: "Minimiser l'utilisation d'expressions idiomatiques",
            more_symbolism: "Renforcer l'utilisation du symbolisme",
            less_symbolism: "Réduire l'utilisation du symbolisme",
          },
        },
        conciseness: {
          label: "Concision",
          choices: {
            more_concise: "Rendre plus concis",
            more_wordy: "Rendre plus détaillé",
            remove_redundant: "Éliminer les phrases redondantes",
            add_details: "Ajouter plus d'explications détaillées",
            reduce_filler: "Réduire les mots de remplissage",
            add_elaboration: "Inclure plus d'élaborations",
          },
        },
        imagery: {
          label: "Imagerie et Détails Sensoriels",
          choices: {
            enhance_imagery: "Améliorer l'imagerie",
            simplify_imagery: "Simplifier l'imagerie",
            vivid_descriptions: "Utiliser des descriptions plus vivantes",
            straightforward_descriptions:
              "Utiliser des descriptions plus directes",
            more_visual: "Incorporer plus de détails visuels",
            less_visual: "Réduire les détails visuels",
          },
        },
        paragraph: {
          label: "Structure des Paragraphes et du Texte",
          choices: {
            shorter_paragraphs: "Raccourcir les paragraphes",
            longer_paragraphs: "Allonger les paragraphes",
            break_sections: "Diviser le texte en sections plus petites",
            combine_sections: "Combiner les sections pour un meilleur flux",
            more_lists: "Utiliser plus de listes à puces",
            more_continuous: "Utiliser plus de texte continu",
            vary_paragraphs: "Augmenter la variété des paragraphes",
            consistent_length: "Maintenir une longueur de paragraphe cohérente",
          },
        },
        other: {
          label: "Autres Aspects",
          choices: {
            replace_context:
              "Remplacer les références CONTEXT par le nom réel de la source",
            add_numbering: "Ajouter la numérotation des paragraphes",
            remove_numbering: "Supprimer la numérotation des paragraphes",
            extend_statutories: "Étendre le texte sur les statuts",
            reduce_statutories: "Réduire le texte sur les statuts",
            extend_jurisprudence: "Étendre le texte sur la jurisprudence",
            reduce_jurisprudence: "Réduire le texte sur la jurisprudence",
          },
        },
      },
    },
    "prompt-step": {
      title: "Sélectionner un Prompt",
      description: "Choisissez comment vous voulez améliorer la réponse",
    },
    actions: {
      next: "Suivant",
      back: "Retour",
      upgrade: "Améliorer la Réponse",
      cancel: "Annuler",
    },
  },
  "validate-response": {
    title: "Résultat de la validation",
    "toast-fail": "Impossible de valider la réponse",
    validating: "Validation de la réponse",
    button: "Valider la réponse",
    "adjust-prefix":
      "Apportez toutes les modifications indiquées à la réponse sur la base de ce retour : ",
    "adjust-button": "Appliquer les modifications suggérées",
  },

  "workspace-names": {
    "Administrative Law": "Droit administratif",
    "Business Law": "Droit des affaires",
    "Civil Law": "Droit civil",
    "Criminal Law": "Droit pénal",
    "Diplomatic Law": "Droit diplomatique",
    "Fundamental Law": "Droit fondamental",
    "Human Rights Law": "Droit des droits de l'homme",
    "Judicial Laws": "Lois judiciaires",
    "Security Laws": "Lois de sécurité",
    "Taxation Laws": "Lois fiscales",
  },
  "validation-prompt": {
    title: "Invite de validation",
    description:
      "Ce paramètre contrôle l'invite de validation par défaut qui sera envoyée au LLM pour valider la réponse donnée.",
    placeholder:
      "Veuillez valider la réponse suivante en vérifiant l'exactitude de toutes les références juridiques et citations par rapport au contexte fourni. Énumérez toutes les inexactitudes ou représentations erronées trouvées.",
  },
  "validate-answer": {
    setting: "Validation LLM",
    title: "Préférence de Validation LLM",
    description:
      "Ce sont les identifiants et paramètres pour votre fournisseur préféré de validation LLM chat et embedding. Il est important que ces clés soient à jour et correctes, sinon le système ne fonctionnera pas correctement.",
    "toast-success": "Paramètres de validation LLM mis à jour",
    "toast-fail": "Échec de la mise à jour des paramètres de validation LLM",
    saving: "Enregistrement...",
    "save-changes": "Enregistrer les modifications",
  },
};

export default TRANSLATIONS;
