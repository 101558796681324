const TRANSLATIONS = {
  common: {
    "workspaces-name": "Arbetsytans namn",
    error: "fel",
    success: "framgång",
    user: "Användare",
    selection: "Modellval",
    saving: "Sparar...",
    save: "Spara ändringar",
    previous: "Föregående sida",
    next: "Nästa sida",
  },

  // Popup Confirmation
  deleteWorkspaceConfirmation:
    "Är du säker på att du vill ta bort {{name}}?\nEfter detta kommer det inte att vara tillgängligt i denna instans.\n\nDenna åtgärd går inte att ångra.",
  deleteConfirmation:
    "Är du säker på att du vill ta bort {{username}}?\nEfter detta kommer de att bli utloggade och inte kunna använda denna instans.\n\nDenna åtgärd går inte att ångra.",
  suspendConfirmation:
    "Är Du säker på att Du vill avaktivera av kontot för {{username}}?\nEfter bekräftelse kommer användaren att bli utloggad och kan inte logga in igen innan kontot återaktiverats.",
  flushVectorCachesWorkspaceConfirmation:
    "Är du säker på att du vill tömma vektorcacheminnet för den här arbetsytan?",

  // Setting Sidebar menu items.
  settings: {
    title: "Instansinställningar",
    system: "Allmänna inställningar",
    invites: "Inbjudningar",
    users: "Användare",
    workspaces: "Arbetsytor",
    "workspace-chats": "Arbetsytans chattar",
    customization: "Anpassning",
    "api-keys": "Utvecklar-API",
    llm: "LLM",
    transcription: "Transkription",
    embedder: "Inbäddare",
    "text-splitting": "Textdelning & Chunking",
    "vector-database": "Vektordatabas",
    embeds: "Chat Inbäddning",
    "embed-chats": "Inbäddningschattens historik",
    security: "Säkerhet",
    "event-logs": "Händelseloggar",
    privacy: "Sekretess & Data",
    "ai-providers": "AI-leverantörer",
    "agent-skills": "Agentfärdigheter",
    admin: "Administratör",
    tools: "Verktyg",
    audio: "Ljudinställningar",
    "link-settings": "Inställningar",
    "default-settings": "Standardinställningar",
    "browser-extension": "Browser-tillägg",
    "prompt-upgrade-llm": "Prompt-förbättrings-LLM",
  },

  // Qura buttons,
  qura: {
    "copy-to-cora": "Qura källkontroll",
    "qura-status": "Qura-knappen är ",
    "copy-option": "kopieringsalternativ",
    "option-quest": "Fråga",
    "option-resp": "Svar",
    "role-description": "Lägg till en Qura-knapp för att få svar på Qura.law",
  },

  thread_name_error:
    "Trådens namn måste vara minst 3 tecken långt och endast innehålla bokstäver, siffror, mellanslag eller bindestreck.",

  // Page Definitions
  login: {
    "multi-user": {
      welcome: "Välkommen till",
      "placeholder-username": "E-postadress",
      "placeholder-password": "Lösenord",
      login: "Logga in",
      validating: "Validerar...",
      "forgot-pass": "Glömt lösenord",
      reset: "Återställ",
    },
    "sign-in": {
      start: "Logga in på ditt konto",
      end: "konto.",
    },
    button: "logga in",
    password: {
      forgot: "GLÖMT LÖSENORD?",
      contact: "Kontakta systemadministratören.",
    },
    publicMode: "Offentligt läge",
  },

  "new-workspace": {
    title: "Ny arbetsyta",
    placeholder: "Min arbetsyta",
    "legal-areas": "Rättsområden",
    create: {
      title: "Skapa ny arbetsyta",
      description:
        "Efter att ha skapat denna arbetsyta kommer endast administratörer att kunna se det. Du kan lägga till användare efter att det har skapats.",
      error: "Fel: ",
      cancel: "Avbryt",
      "create-workspace": "Skapa arbetsyta",
    },
  },

  contextual: {
    checkbox: {
      label: "Kontextuell inbäddning",
      hint: "Aktivera kontextuell inbäddning för att förbättra inbäddningsprocessen med ytterligare parametrar",
    },
    systemPrompt: {
      label: "Systemmeddelande",
      placeholder: "Ange ett värde...",
      description:
        "Exempel: Ge en kort och koncis kontext för att placera detta stycke inom hela dokumentet för att förbättra sökningen av stycket. Svara endast med den koncisa kontexten och inget annat.",
    },
    userPrompt: {
      label: "Användarmeddelande",
      placeholder: "Ange ett värde...",
      description:
        "Exempel: <document>\n{file}\n</document>\nHär är stycket vi vill placera inom hela dokumentet\n<chunk>\n{chunk}\n</chunk>",
    },
  },

  "workspace-chats": {
    welcome: "Välkommen till din nya arbetsyta.",
    "desc-start": "För att komma igång, antingen",
    "desc-mid": "ladda upp ett dokument",
    "desc-or": "eller",
    start: "För att komma igång",
    "desc-end": "skicka ett meddelande.",
    prompt: {
      send: "Skicka",
      "send-message": "Skicka meddelande",
      placeholder: "Be om juridisk information",
      slash: "Visa alla tillgängliga kommandon för att chatta.",
      "change-size": "Ändra textstorlek",
      reset: "/återställ",
      clear: "Rensa din chatthistorik och börja en ny chatt",
      "new-preset": "Lägg till ny förinställning",
      command: "Kommando",
      description: "Beskrivning",
      save: "spara",
      small: "Liten",
      normal: "Normal",
      large: "Stor",
      attach: "Bifoga en fil till denna chatt",
      upgrade: "Uppgradera Din prompt",
      upgrading: "Uppgraderar Din prompt",
      "original-prompt": "Ursprunglig prompt:",
      "upgraded-prompt": "Uppgraderad prompt:",
      "edit-prompt": "Du kan redigera den nya prompten innan Du skickar in den",
    },
  },

  header: {
    account: "Konto",
    login: "Logga in",
    "sign-out": "Logga ut",
  },

  workspace: {
    title: "Instansarbetsytor",
    description:
      "Detta är alla arbetsytor som finns på denna instans. Att ta bort en arbetsyta raderar alla dess associerade chattar och inställningar.",
    "new-workspace": "Ny Arbetsyta",
    name: "Namn",
    link: "Länk",
    users: "Användare",
    type: "Typ",
    "created-on": "Skapad Den",
    save: "Spara ändringar",
    cancel: "Avbryt",
    deleted: {
      title: "Arbetsyta inte hittad!",
      description:
        "Det verkar som att en arbetsyta med detta namn inte är tillgänglig.",
      homepage: "Gå tillbaka till startsidan",
    },
    "no-workspace": {
      title: "Inget arbetsområde tillgängligt",
      description: "Du har ännu inte tillgång till några arbetsområden.",
      "contact-admin":
        "Vänligen kontakta din administratör för att begära åtkomst.",
      "learn-more": "Lär dig mer om arbetsområden",
    },
  },

  // Workspace Settings menu items
  "workspaces-settings": {
    general: "Allmänna Inställningar",
    chat: "Chattinställningar",
    vector: "Vektordatabas",
    members: "Medlemmar",
    agent: "Agentkonfiguration",
    "general-settings": {
      "workspace-name": "Arbetsrymmets Namn",
      "desc-name":
        "Detta kommer bara att ändra visningsnamnet på ditt arbetsrymme.",
      "assistant-profile": "Assistentens Profilbild",
      "assistant-image":
        "Anpassa profilbilden för assistenten för detta arbetsrymme.",
      "workspace-image": "Arbetsrymmets Bild",
      "remove-image": "Ta Bort Arbetsrymmets Bild",
      delete: "Radera Arbetsrymme",
      deleting: "Raderar Arbetsrymme...",
      update: "Uppdatera arbetsyta",
      updating: "Uppdaterar arbetsyta...",
    },
    "chat-settings": {
      type: "Chatt Typ",
      private: "Privat",
      standard: "Standard",
      "private-desc-start": "kommer manuellt att bevilja tillgång till",
      "private-desc-mid": "endast",
      "private-desc-end": "specifika användare.",
      "standard-desc-start": "kommer automatiskt att bevilja tillgång till",
      "standard-desc-mid": "alla",
      "standard-desc-end": "nya användare.",
    },
    users: {
      manage: "Hantera Användare",
      "workspace-member": "Inga arbetsrymmesmedlemmar",
      username: "E-postadress",
      role: "Roll",
      date: "Tillagd Datum",
      users: "Användare",
      search: "Sök efter en användare",
      "no-user": "Inga användare hittades",
      select: "Välj Alla",
      unselect: "Avmarkera Alla",
      save: "Spara",
    },
    "linked-workspaces": {
      title: "Arbetsytor Länkade",
      description:
        "Om arbetsytor är länkade kommer juridisk data som är relevant för prompten automatiskt att hämtas från varje länkat juridiskt område. Observera att länkade arbetsytor kommer att göra bearbetningstiden längre",
      "linked-workspace": "Inga länkade arbetsytor",
      manage: "Hantera Arbetsytor",
      name: "Namn",
      slug: "Slug",
      date: "Datum tillagt",
      workspaces: "Arbetsytor",
      search: "Sök efter en arbetsyta",
      "no-workspace": "Inga arbetsytor hittades",
      select: "Välj Alla",
      unselect: "Avmarkera",
      save: "Spara",
    },
  },

  // General Appearance
  general: {
    vector: {
      title: "Antal vektorer",
      description: "Totala antalet vektorer i din vektordatabas.",
      vectors: "Antal vektorer",
    },
    names: {
      description:
        "Detta kommer endast att ändra visningsnamnet på din arbetsyta.",
    },
    message: {
      title: "Föreslagna meddelanden",
      description:
        "Anpassa de meddelanden som kommer att föreslås för dina arbetsytsanvändare.",
      add: "Lägg till nytt meddelande",
      save: "Spara meddelanden",
      heading: "Förklara för mig",
      body: "fördelarna med plattformen",
      message: "Meddelande",
      "new-heading": "Rubrik",
    },
    pfp: {
      title: "Profilbild för assistent",
      description: "Anpassa profilbilden för assistenten för denna arbetsyta.",
      image: "Arbetsytans bild",
      remove: "Ta bort arbetsytans bild",
    },
    delete: {
      delete: "Radera arbetsyta",
      deleting: "Raderar arbetsyta...",
      "confirm-start": "Du håller på att radera hela din",
      "confirm-end":
        "arbetsyta. Detta kommer att ta bort alla vektorinbäddningar i din vektordatabas. \n \n Originalkällfilerna kommer att förbli orörda. Denna åtgärd är oåterkallelig.",
    },
  },

  // Chat Settings
  chat: {
    llm: {
      title: "Arbetsytans LLM-leverantör",
      description:
        "Den specifika LLM-leverantören & modellen som kommer att användas för denna arbetsyta. Som standard använder den systemets LLM-leverantör och inställningar.",
      search: "Sök alla LLM-leverantörer",
    },
    model: {
      title: "Arbetsytans chattmodell",
      description:
        "Den specifika chattmodellen som kommer att användas för denna arbetsyta. Om tomt kommer systemets LLM-preferens att användas.",
      wait: "-- väntar på modeller --",
    },
    mode: {
      title: "Chattläge",
      chat: {
        title: "Chatt",
        "desc-start": "kommer att ge svar med LLM:s allmänna kunskap",
        and: "och",
        "desc-end": "dokumentkontext som hittas.",
      },
      query: {
        title: "Fråga",
        "desc-start": "kommer att ge svar",
        only: "endast",
        "desc-end": "om dokumentkontext hittas.",
      },
    },
    history: {
      title: "Chattens historik",
      "desc-start":
        "Antalet tidigare chattar som kommer att inkluderas i svarets korttidsminne.",
      recommend: "Rekommenderar 20. ",
      "desc-end":
        "Allt mer än 45 är sannolikt att leda till kontinuerliga chattfel beroende på meddelandestorlek.",
    },
    prompt: {
      title: "Prompt",
      description:
        "Prompten som kommer att användas på denna arbetsyta. Definiera kontext och instruktioner för AI:n för att generera ett svar. Du bör tillhandahålla en noggrant utformad prompt så att AI:n kan generera ett relevant och korrekt svar.",
    },
    refusal: {
      title: "Vägrande svar i frågeläge",
      "desc-start": "När i",
      query: "fråga",
      "desc-end":
        "läge, kanske du vill återge ett anpassat vägrande svar när ingen kontext hittas.",
    },
    temperature: {
      title: "LLM:s temperatur",
      "desc-start":
        'Denna inställning styr hur "kreativa" dina LLM-svar kommer att vara.',
      "desc-end":
        "Ju högre nummer desto mer kreativt. För vissa modeller kan detta leda till osammanhängande svar när det är inställt för högt.",
      hint: "De flesta LLM:er har olika acceptabla värden. Konsultera din LLM-leverantör för den informationen.",
    },
    "dynamic-pdr": {
      title: "Arbetsyta Dynamisk PDR",
      description:
        "Aktivera eller inaktivera Dynamisk PDR för denna arbetsyta.",
    },
  },

  // Vector Database
  "vector-workspace": {
    identifier: "Identifierare för vektordatabas",
    snippets: {
      title: "Maximalt antal kontextsnippets",
      description:
        "Denna inställning styr det maximala antalet kontextsnippets som kommer att skickas till LLM per chatt eller fråga.",
      recommend: "Rekommenderat: 4",
    },
    doc: {
      title: "Tröskelvärde för dokumentsimilaritet",
      description:
        "Den minsta likhetspoängen som krävs för att en källa ska anses vara relaterad till chatten. Ju högre poäng, desto mer lik måste källan vara chatten.",
      zero: "Ingen begränsning",
      low: "Låg (likhetspoäng ≥ .25)",
      medium: "Medel (likhetspoäng ≥ .50)",
      high: "Hög (likhetspoäng ≥ .75)",
    },
    reset: {
      reset: "Återställ vektordatabas",
      resetting: "Rensar vektorer...",
      confirm:
        "Du håller på att återställa vektordatabasen för denna arbetsyta. Detta kommer att ta bort alla vektorinbäddningar som för närvarande är inbäddade. \n \n Originalkällfilerna kommer att förbli orörda. Denna åtgärd är oåterkallelig.",
      error: "Vektordatabasen för arbetsytan kunde inte återställas!",
      success: "Vektordatabasen för arbetsytan återställdes!",
    },
  },

  // Agent Configuration
  agent: {
    "performance-warning":
      "Prestandan hos LLM:er som inte uttryckligen stöder verktygskallning är starkt beroende av modellens kapacitet och noggrannhet. Vissa förmågor kan vara begränsade eller icke-funktionella.",
    provider: {
      title: "Arbetsytans LLM-leverantör",
      description:
        "Den specifika LLM-leverantören & modellen som kommer att användas för denna arbetsytas @agent agent.",
    },
    mode: {
      chat: {
        title: "Arbetsytans agentchattmodell",
        description:
          "Den specifika chattmodellen som kommer att användas för denna arbetsytas @agent agent.",
      },
      title: "Arbetsytans agentmodell",
      description:
        "Den specifika LLM-modellen som kommer att användas för denna arbetsytas @agent agent.",
      wait: "-- väntar på modeller --",
    },

    skill: {
      title: "Standardagentens färdigheter",
      description:
        "Förbättra de naturliga förmågorna hos standardagenten med dessa förbyggda färdigheter. Denna inställning gäller för alla arbetsytor.",
      rag: {
        title: "RAG & långtidsminne",
        description:
          'Låt agenten utnyttja dina lokala dokument för att svara på en fråga eller be agenten "komma ihåg" delar av innehållet för långtidsminneshämtning.',
      },
      configure: {
        title: "Konfigurera Agentens Färdigheter",
        description:
          "Anpassa och förbättra agentens standardkapaciteter genom att aktivera eller inaktivera specifika färdigheter. Dessa inställningar kommer att tillämpas på alla arbetsytor.",
      },
      view: {
        title: "Visa & sammanfatta dokument",
        description:
          "Låt agenten lista och sammanfatta innehållet i arbetsytans filer som för närvarande är inbäddade.",
      },
      scrape: {
        title: "Hämta innehåll från webbplatser",
        description:
          "Låt agenten besöka och hämta innehållet från webbplatser.",
      },
      generate: {
        title: "Generera diagram",
        description:
          "Aktivera standardagenten för att generera olika typer av diagram från data som tillhandahålls eller ges i chatten.",
      },
      save: {
        title: "Generera & spara filer till webbläsaren",
        description:
          "Aktivera standardagenten för att generera och skriva till filer som kan sparas och laddas ner i din webbläsare.",
      },
      web: {
        title: "Live webbsökning och surfning",
        "desc-start":
          "Aktivera din agent för att söka på webben för att besvara dina frågor genom att ansluta till en webb-sök (SERP) leverantör.",
        "desc-end":
          "Webbsökning under agentsessioner fungerar inte förrän detta är inställt.",
      },
    },
  },

  // Workspace Chats
  recorded: {
    title: "Arbetsytans chattar",
    description:
      "Detta är alla inspelade chattar och meddelanden som har skickats av användare ordnade efter deras skapelsedatum.",
    export: "Exportera",
    table: {
      id: "Id",
      by: "Skickat av",
      workspace: "Arbetsyta",
      prompt: "Prompt",
      response: "Svar",
      at: "Skickat vid",
    },
    "clear-chats": "Rensa Chattar",
    "confirm-clear-chats":
      "Är du säker på att du vill rensa alla chattar?\n\nDenna åtgärd kan inte ångras.",
    "fine-tune-modal": "Beställ Fine-Tune-modell",
    "confirm-delete.chat":
      "Är du säker på att du vill ta bort denna chatt?\n\nDenna åtgärd kan inte ångras.",
    next: "Nästa sida",
    previous: "Föregående sida",
  },

  // Appearance
  appearance: {
    title: "Utseende",
    description: "Anpassa utseendeinställningarna på din plattform.",
    logo: {
      title: "Anpassa logotyp",
      description:
        "Ladda upp din anpassade logotyp för att göra din chatbot till din egen.",
      add: "Lägg till en anpassad logotyp",
      recommended: "Rekommenderad storlek: 800 x 200",
      remove: "Ta bort",
    },
    logoDark: {
      title: "Anpassa logotyp för mörkt läge",
      description: "Ladda upp din anpassade logotyp för mörkt läge.",
    },
    message: {
      title: "Anpassa meddelanden",
      description:
        "Anpassa de automatiska meddelandena som visas för dina användare.",
      new: "Ny",
      system: "system",
      user: "användare",
      message: "meddelande",
      assistant: "chattassistent",
      "double-click": "Dubbelklicka för att redigera...",
      save: "Spara meddelanden",
    },
    icons: {
      title: "Anpassade sidfotsikoner",
      description: "Anpassa sidfotsikonerna som visas längst ner i sidofältet.",
      icon: "Ikon",
      link: "Länk",
    },
    display: {
      title: "Visningsspråk",
      description: "Välj det föredragna språket.",
    },
    color: {
      title: "Anpassade färger",
      "desc-start": "Anpassa",
      "desc-mid": "*bakgrundsfärg, *primär färg",
      "desc-and": "och",
      "desc-end": "*textfärg",
      red: "Röd",
      gray: "Grå",
      foynet: "Foynet",
      brown: "Brun",
      green: "Grön",
      yellow: "Gul",
      cyan: "Cyan",
      magenta: "Magenta",
      orange: "TenderFlow",
      purple: "Lila",
      navy: "Marinblå",
      black: "Svart",
    },
    login: {
      title: "Anpassad inloggningstext",
      description: "Anpassa texten som visas på inloggningssidan.",
      placeholder: "Vänligen kontakta systemadministratören",
      website: {
        title: "Anpassa webbplatsens URL",
        description: "Anpassa URL:en för din applikations webbplats.",
        toggle: "Visa webbplatslänk",
        save: "Spara",
      },
      validation: {
        invalidURL: "Ange en giltig URL.",
      },
    },
    siteSettings: {
      title: "Anpassade webbplatsinställningar",
      description:
        "Ändra innehållet i webbläsarfliken för anpassning och varumärkning.",
      tabTitle: "Flikrubrik",
      tabDescription:
        "Ange en anpassad flikrubrik när appen är öppen i en webbläsare.",
      tabIcon: "Flik-favicon",
      fabIconUrl: "Ange en URL till en bild att använda som favicon",
      placeholder: "URL till din ikon",
    },
    appName: {
      title: "Anpassat appnamn",
      description: "Ange ett anpassat appnamn som visas på inloggningssidan.",
      save: "Spara",
    },
    customTab: {
      title: "Anpassa fliknamn",
      tab1: "Flik 1",
      tab2: "Flik 2",
    },
  },

  // API Keys
  api: {
    title: "API-nycklar",
    description:
      "API-nycklar tillåter innehavaren att programmässigt komma åt och hantera denna plattform.",
    link: "Läs API-dokumentationen",
    generate: "Generera ny API-nyckel",
    table: {
      key: "API-nyckel",
      by: "Skapad av",
      created: "Skapad",
    },
    new: {
      title: "Skapa ny API-nyckel",
      description:
        "När den är skapad kan API-nyckeln användas för att programmässigt få åtkomst till och konfigurera denna plattform.",
      doc: "Läs API-dokumentationen",
      cancel: "Avbryt",
      "create-api": "Skapa API-nyckel",
    },
  },

  llm: {
    title: "LLM-preferens",
    description:
      "Dessa är dina referenser och inställningar för din föredragna LLM-chatt- och inbäddningstjänst. Det är viktigt att dessa nycklar är aktuella och korrekta, annars kommer systemet inte att fungera korrekt.",
    provider: "LLM-leverantör",
    "none-selected": "Ingen vald",
    "select-llm": "Du måste välja en LLM",
    "search-llm": "Sök alla LLM-leverantörer",
    "prompt-upgrade": {
      title: "LLM-leverantör för promptförbättring",
      description:
        "Den specifika LLM-leverantören och modellen som kommer att användas för att förbättra användarprompter. Som standard används systemets LLM-leverantör och inställningar.",
      search: "Sök tillgängliga LLM-leverantörer för funktionen",
    },
  },

  "llm-provider": {
    openai:
      "Det standardalternativet för de flesta icke-kommersiella användningsområden.",
    azure: "Företagsalternativet för OpenAI som hostas på Azure-tjänster.",
    anthropic: "En vänlig AI-assistent hostad av Anthropic.",
    gemini: "Googles största och mest kapabla AI-modell",
    huggingface:
      "Åtkomst till 150 000+ open-source LLM:er och världens AI-gemenskap",
    ollama: "Kör LLM:er lokalt på din egen maskin.",
    lmstudio:
      "Upptäck, ladda ner och kör tusentals banbrytande LLM:er på några klick.",
    localai: "Kör LLM:er lokalt på din egen maskin.",
    togetherai: "Kör open source-modeller från Together AI.",
    mistral: "Kör open source-modeller från Mistral AI.",
    perplexityai:
      "Kör kraftfulla och internetanslutna modeller hostade av Perplexity AI.",
    openrouter: "Ett enhetligt gränssnitt för LLM:er.",
    groq: "Den snabbaste LLM-inferensen som finns för realtids-AI-applikationer.",
    koboldcpp: "Kör lokala LLM:er med koboldcpp.",
    oobabooga: "Kör lokala LLM:er med Oobaboogas Text Generation Web UI.",
    cohere: "Kör Cohere:s kraftfulla Command-modeller.",
    lite: "Kör LiteLLM:s OpenAI-kompatibla proxy för olika LLM:er.",
    "generic-openai":
      "Anslut till vilken OpenAi-kompatibel tjänst som helst via en anpassad konfiguration",
    native:
      "Använd en nedladdad anpassad Llama-modell för att chatta på denna plattform.",
  },

  // audio preference
  audio: {
    title: "Inställningar för Tal-till-text",
    provider: "Leverantör",
    "desc-speech":
      "Här kan du specificera vilken typ av tal-till-text- och text-till-tal-leverantörer du vill använda. Som standard använder vi webbläsarens inbyggda stöd för dessa tjänster, men du kan välja att använda andra.",
    "title-text": "Inställningar för Text-till-tal",
    "desc-text":
      "Här kan du specificera vilken typ av text-till-tal-leverantörer du vill använda. Som standard använder vi webbläsarens inbyggda stöd för dessa tjänster, men du kan välja att använda andra.",
    "desc-config": "Ingen konfiguration behövs för denna leverantör.",
    "placeholder-stt": "Sök tal-till-text-leverantörer",
    "placeholder-tts": "Sök text-till-tal-leverantörer",
    "native-stt": "Använder webbläsarens inbyggda STT-tjänst om det stöds.",
    "native-tts": "Använder webbläsarens inbyggda TTS-tjänst om det stöds.",
    openai: "Använd OpenAIs text-till-tal-röster.",
    elevenlabs: "Använd ElevenLabs text-till-tal-röster och teknik.",
  },

  transcription: {
    title: "Transkriptionsmodellpreferens",
    description:
      "Detta är dina referenser och inställningar för din föredragna transkriptionsmodellleverantör. Det är viktigt att dessa nycklar är aktuella och korrekta, annars transkriberas inte mediefiler och ljud.",
    provider: "Transkriptionsleverantör",
    "warn-start":
      "Användning av den lokala whisper-modellen på maskiner med begränsat RAM eller CPU kan stoppa plattformen vid bearbetning av mediefiler.",
    "warn-recommend":
      "Vi rekommenderar minst 2 GB RAM och uppladdade filer <10Mb.",
    "warn-end":
      "Den inbyggda modellen kommer att laddas ner automatiskt vid första användning.",
    "search-audio": "Sök efter ljudtranskriptionsleverantörer",
  },

  embedding: {
    title: "Inbäddningspreferens",
    "desc-start":
      "När du använder en LLM som inte nativt stöder en inbäddningsmotor - kan du behöva ange ytterligare referenser för att inbädda text.",
    "desc-end":
      "Inbäddning är processen att omvandla text till vektorer. Dessa referenser krävs för att omvandla dina filer och prompts till ett format som plattformen kan använda för att bearbeta.",
    provider: {
      title: "Inbäddningsleverantör",
      description:
        "Ingen konfiguration behövs när du använder plattformens inbyggda inbäddningsmotor.",
      "search-embed": "Sök alla inbäddningsleverantörer",
    },
    workspace: {
      title: "Inställning för Arbetsyta Embedding",
      description:
        "Den specifika embedding-leverantören och modellen som kommer att användas för denna arbetsyta. Som standard används systemets embedding-leverantör och inställningar.",
      "multi-model": "Multi-modellstöd stöds ännu inte för denna leverantör.",
      "workspace-use": "Denna arbetsyta kommer att använda",
      "model-set": "modellen som är inställd för systemet.",
      embedding: "Embedding-modell för Arbetsyta",
      model:
        "Den specifika embedding-modellen som kommer att användas för denna arbetsyta. Om den är tom används systemets embedding-preferens.",
      wait: "-- väntar på modeller --",
      setup: "Installera",
      use: "För att använda",
      "need-setup":
        "som embedding för denna arbetsyta måste du först konfigurera den.",
      cancel: "Avbryt",
      save: "Spara",
      settings: "Inställningar",
      search: "Sök bland alla embedding-leverantörer",
      "need-llm": "som LLM för denna arbetsyta måste du först konfigurera den.",
    },
  },

  text: {
    title: "Textdelning & Chunking preferenser",
    "desc-start":
      "Ibland kanske du vill ändra standardmetoden för hur nya dokument delas och delas innan de sätts in i din vektordatabas.",
    "desc-end":
      "Du bör bara ändra denna inställning om du förstår hur textdelning fungerar och dess bieffekter.",
    "warn-start": "Ändringar här gäller endast",
    "warn-center": "nyinbäddade dokument",
    "warn-end": ", inte befintliga dokument.",
    size: {
      title: "Textchunkstorlek",
      description:
        "Detta är den maximala längden av tecken som kan finnas i en enskild vektor.",
      recommend: "Inbäddningsmodellens maximala längd är",
    },

    overlap: {
      title: "Textchunk överlappning",
      description:
        "Detta är den maximala överlappningen av tecken som inträffar under chunking mellan två angränsande textchunks.",
    },
  },

  // Vector Database
  vector: {
    title: "Vektordatabas",
    description:
      "Detta är dina referenser och inställningar för hur din plattform kommer att fungera. Det är viktigt att dessa nycklar är aktuella och korrekta.",
    provider: {
      title: "Vektordatabasleverantör",
      description: "Ingen konfiguration behövs för LanceDB.",
      "search-db": "Sök alla vektordatabasleverantörer",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Inbäddningsbara chattwidgets",
    description:
      "Inbäddningsbara chattwidgets är offentliga chattgränssnitt som är kopplade till en enda arbetsyta. Dessa låter dig bygga arbetsytor som du sedan kan publicera för världen.",
    create: "Skapa inbäddning",
    table: {
      workspace: "Arbetsyta",
      chats: "Skickade chattar",
      Active: "Aktiva domäner",
    },
  },

  "embed-chats": {
    title: "Inbäddningschattar",
    export: "Exportera",
    description:
      "Detta är alla inspelade chattar och meddelanden från alla inbäddningar som du har publicerat.",
    table: {
      embed: "Inbäddning",
      sender: "Avsändare",
      message: "Meddelande",
      response: "Svar",
      at: "Skickat vid",
    },
  },

  multi: {
    title: "Multi-användarläge",
    description:
      "Ställ in din instans för att stödja ditt team genom att aktivera Multi-användarläge.",
    enable: {
      "is-enable": "Multi-användarläge är aktiverat",
      enable: "Aktivera Multi-användarläge",
      description:
        "Som standard kommer du att vara den enda administratören. Som administratör måste du skapa konton för alla nya användare eller administratörer. Förlora inte ditt lösenord eftersom endast en administratörsanvändare kan återställa lösenord.",
      username: "Administratörskontots användarnamn",
      password: "Administratörskontots lösenord",
    },
    password: {
      title: "Lösenordsskydd",
      description:
        "Skydda din plattform med ett lösenord. Om du glömmer detta finns det ingen återställningsmetod, så se till att du sparar detta lösenord.",
    },
    instance: {
      title: "Lösenordsskydda instans",
      description:
        "Som standard kommer du att vara den enda administratören. Som administratör måste du skapa konton för alla nya anv��ndare eller administratörer. Förlora inte ditt lösenord eftersom endast en administratörsanvändare kan återställa lösenord.",
      password: "Instanslösenord",
    },
  },

  // Event Logs
  event: {
    title: "Händelseloggar",
    description:
      "Visa alla åtgärder och händelser som händer på denna instans för övervakning.",
    clear: "Rensa händelseloggar",
    table: {
      type: "Händelsetyp",
      user: "Användare",
      occurred: "Inträffade vid",
    },
  },

  // Privacy & Data-Handling
  privacy: {
    title: "Sekretess & Datahantering",
    description:
      "Detta är din konfiguration för hur anslutna tredjepartsleverantörer och plattformen hanterar dina data.",
    llm: "LLM-val",
    embedding: "Inbäddningspreferens",
    vector: "Vektordatabas",
    anonymous: "Anonym telemetri aktiverad",
    "desc-event": "Alla händelser registrerar inte IP-adress och innehåller",
    "desc-id": "ingen identifiering",
    "desc-cont":
      "innehåll, inställningar, chattar eller annan icke-användningsbaserad information. För att se listan över insamlade händelsetaggar kan du titta på",
    "desc-git": "Github här",
    "desc-end":
      "Som ett open-source-projekt respekterar vi din rätt till sekretess. Vi är dedikerade till att bygga den bästa lösningen för att integrera AI och dokument privat och säkert. Om du bestämmer dig för att stänga av telemetri ber vi dig bara att överväga att skicka oss feedback och tankar så att vi kan fortsätta förbättra plattformen",
  },
  "default-chat": {
    welcome: "Välkommen till IST Legal.",
    "choose-legal": "Välj ett juridiskt område till vänster.",
  },
  invites: {
    title: "Inbjudningar",
    description:
      "Skapa inbjudningslänkar för personer i din organisation för att acceptera och registrera sig med. Inbjudningar kan endast användas av en enda användare.",
    link: "Skapa inbjudningslänk",
    accept: "Accepterad av",
    "created-by": "Skapad av",
    created: "Skapad",
    new: {
      title: "Skapa ny inbjudan",
      "desc-start":
        "Efter skapandet kan du kopiera inbjudan och skicka den till en ny användare där de kan skapa ett konto som",
      "desc-mid": "standard",
      "desc-end": "roll och automatiskt läggas till valda arbetsytor.",
      "auto-add": "Lägg till inbjudna automatiskt till arbetsytor",
      "desc-add":
        "Du kan valfritt tilldela användaren till arbetsytorna nedan genom att välja dem. Som standard kommer användaren inte att ha några synliga arbetsytor. Du kan tilldela arbetsytor senare efter att inbjudan har accepterats.",
      cancel: "Avbryt",
      "create-invite": "Skapa inbjudan",
      error: "Fel: ",
    },
    "link-copied": "Inbjudningslänk kopierad",
    "copy-link": "Kopiera inbjudningslänk",
    "delete-invite-confirmation":
      "Är du säker på att du vill inaktivera denna inbjudan?\nEfter det kommer den inte längre att vara användbar.\n\nDenna åtgärd är oåterkallelig.",
    status: {
      label: "Status",
      pending: "Väntande",
      disabled: "Inaktiverad",
      claimed: "Accepterad",
    },
  },

  "user-menu": {
    edit: "Redigera konto",
    profile: "Profilbild",
    size: "800 x 800",
    "remove-profile": "Ta bort profilbild",
    username: "E-postadress",
    "username-placeholder": "Ange e-postadress",
    "new-password": "Nytt lösenord",
    "new-password-placeholder": "nytt lösenord",
    cancel: "Avbryt",
    update: "Uppdatera konto",
    language: "Föredraget språk",
    email: "E-postadress",
    "email-placeholder": "Ange e-postadress",
  },

  "user-setting": {
    description:
      "Detta är alla konton som har ett konto på denna instans. Att ta bort ett konto kommer omedelbart att ta bort deras åtkomst till denna instans.",
    "add-user": "Lägg till användare",
    username: "E-postadress",
    role: "Roll",
    default: "Standard",
    manager: "Chef",
    admin: "Administratör",
    "date-added": "Datum tillagd",
    "all-domains": "Alla domäner",
    "other-users": "Andra användare (Utan domän)",
    edit: "Redigera: ",
    "new-password": "Nytt lösenord",
    "password-rule": "Lösenordet måste vara minst 8 tecken långt.",
    "update-user": "Uppdatera användare",
    placeholder: "Ange e-postadress",
    cancel: "Avbryt",
    "remove-user": "Ta bort användare",
    "remove-user-title": "Ta bort användare",
    "remove-user-confirmation":
      "Är du säker på att du vill ta bort denna användare?",
    error: "Fel: ",
    "invalid-email": "Vänligen ange en giltig e-postadress.",
  },

  sidebar: {
    thread: {
      "load-thread": "laddar trådar....",
      "starting-thread": "Startar tråd...",
      thread: "Ny tråd",
      delete: "Ta bort valda",
      rename: "Byt namn",
      "delete-thread": "Ta bort tråd",
      deleted: "raderad",
      default: "Standard",
      "empty-thread": "Tråd",
      "rename-message": "Vad vill du byta namn på denna tråd till?",
      "delete-message":
        "Är du säker på att du vill radera denna tråd? Alla dess chattar kommer att raderas. Du kan inte ångra detta.",
    },
  },

  embeder: {
    allm: "Använd den inbyggda inbäddningsleverantören för plattformen. Ingen inställning!",
    openai:
      "Det standardalternativet för de flesta icke-kommersiella användningsområden.",
    azure: "Företagsalternativet för OpenAI som hostas på Azure-tjänster.",
    localai: "Kör inbäddningsmodeller lokalt på din egen maskin.",
    ollama: "Kör inbäddningsmodeller lokalt på din egen maskin.",
    lmstudio:
      "Upptäck, ladda ner och kör tusentals banbrytande LLM:er på några klick.",
    cohere: "Kör kraftfulla inbäddningsmodeller från Cohere.",
    voyageai: "Kör kraftfulla inbäddningsmodeller från Voyage AI.",
  },

  vectordb: {
    lancedb:
      "100 % lokal vektordatabas som körs på samma server som plattformen",
    chroma:
      "Öppen källkod vektordatabas som du kan vara värd för själv eller på molnet.",
    pinecone: "100 % molnbaserad vektordatabas för företagsanvändning.",
    zilliz: "Molnhostad vektordatabas byggd för företag med SOC 2-efterlevnad.",
    qdrant: "Öppen källkod lokal och distribuerad molnvektordatabas.",
    weaviate: "Öppen källkod lokal och molnhostad multimodal vektordatabas.",
    milvus: "Öppen källkod, mycket skalbar och otroligt snabb.",
    astra: "Vektor-sökning för verkliga GenAI.",
  },

  system: {
    title: "Systeminställningar",
    "desc-start":
      "Detta är de övergripande inställningarna och konfigurationerna för din instans.",
    user: "Användare kan ta bort arbetsytor",
    "desc-delete":
      "Tillåt icke-administratörer att ta bort arbetsytor som de är en del av. Detta skulle ta bort arbetsytan för alla.",
    limit: {
      title: "Begränsa meddelanden per användare per dag",
      "desc-limit":
        "Begränsa icke-administratörer till ett antal lyckade förfrågningar eller chattar inom ett 24-timmarsfönster. Aktivera detta för att förhindra användare från att öka OpenAI-kostnaderna.",
      "per-day": "Meddelandegräns per dag",
      label: "Begränsa meddelanden: ",
    },
    "source-highlighting": {
      title: "Aktivera / Inaktivera källmarkering",
      description: "Dölj eller visa källmarkering för användare.",
      label: "Källhänvisning: ",
    },
    state: {
      enabled: "Aktiverat",
      disabled: "Inaktiverat",
    },
    save: "Spara ändringar",
    max_tokens: {
      title: "Maximalt antal inloggnings-tokens per användare",
      desc: "Ställ in maximalt antal aktiva autentiseringstokens varje användare kan ha samtidigt. När gränsen överskrids kommer äldre tokens att tas bort automatiskt.",
      label: "Maximalt antal tokens",
      help: "Värdet måste vara större än 0",
    },
  },

  support: {
    title: "Support E-post",
    description:
      "Ange supportens e-postadress som visas i användarmenyn när du är inloggad på denna instans.",
    clear: "Rensa",
    save: "Spara",
  },
  "public-mode": {
    enable: "Aktivera Offentligt-Användarläge",
    enabled: "Offentligt-Användarläge är Aktiverat",
  },
  button: {
    delete: "Radera",
    edit: "Redigera",
    suspend: "Avstänga",
    unsuspend: "Återaktivera",
    accept: "Godta",
    decline: "Avböj",
    save: "Spara",
    "flush-vector-caches": "Töm vektorcache",
    saving: "Sparar",
  },

  "new-user": {
    title: "Lägg till användare i instansen",
    username: "E-postadress",
    "username-ph": "Ange e-postadress",
    password: "Lösenord",
    "password-ph": "Användarens initiala lösenord",
    role: "Roll",
    default: "Standard",
    manager: "Chef",
    admin: "Administratör",
    description:
      "Efter att ha skapat en användare måste de logga in med sitt initiala lösenord för att få tillgång.",
    cancel: "Avbryt",
    "add-User": "Lägg till användare",
    "invalid-username":
      "Användarnamnet får endast innehålla gemener, siffror, understreck (_), punkter (.), @-symboler och bindestreck (-)",
    error: "Fel: ",
    "invalid-email": "Ange en giltig e-postadress.",
    permissions: {
      title: "Behörigheter",
      default: [
        "Kan endast skicka chattar med arbetsytor de har lagts till av administratör eller chefer.",
        "Kan inte ändra några inställningar alls.",
      ],
      manager: [
        "Kan visa, skapa och radera alla arbetsytor och ändra arbetsytaspecifika inställningar.",
        "Kan skapa, uppdatera och bjuda in nya användare till instansen.",
        "Kan inte ändra LLM, vectorDB, inbäddning eller andra anslutningar.",
      ],
      admin: [
        "Högsta användarprivilegium.",
        "Kan se och göra allt i systemet.",
      ],
    },
  },

  "new-embed": {
    title: "Skapa ny inbäddning för arbetsyta",
    error: "Fel: ",
    "desc-start":
      "Efter att ha skapat en inbäddning kommer du att få en länk som du kan publicera på din webbplats med ett enkelt",
    script: "script",
    tag: "tag.",
    cancel: "Avbryt",
    "create-embed": "Skapa inbäddning",
    workspace: "Arbetsyta",
    "desc-workspace":
      "Detta är arbetsytan din chattfönster kommer att baseras på. Alla standardinställningar kommer att ärvas från arbetsytan om de inte åsidosätts av denna konfiguration.",
    "allowed-chat": "Tillåten chattmetod",
    "desc-query":
      "Ange hur din chatbot ska fungera. Fråga betyder att den bara svarar om ett dokument hjälper till att besvara frågan.",
    "desc-chat":
      "Chatt öppnar chatten för allmänna frågor och kan svara på frågor som inte alls är relaterade till din arbetsyta.",
    "desc-response": "Chatt: Svara på alla frågor oavsett sammanhang",
    "query-response":
      "Fråga: Svara endast på chattar relaterade till dokument i arbetsytan",
    restrict: "Begränsa förfrågningar från domäner",
    filter:
      "Detta filter blockerar alla förfrågningar som kommer från en annan domän än listan nedan.",
    "use-embed":
      "Att lämna detta tomt betyder att vem som helst kan använda din inbäddning på vilken webbplats som helst.",
    "max-chats": "Max chattar per dag",
    "limit-chats":
      "Begränsa mängden chattar som denna inbäddade chatt kan bearbeta inom en 24-timmarsperiod. Noll är obegränsat.",
    "chats-session": "Max chattar per session",
    "limit-chats-session":
      "Begränsa mängden chattar en sessionsanvändare kan skicka med denna inbäddning inom en 24-timmarsperiod. Noll är obegränsat.",
    "enable-dynamic": "Aktivera dynamisk modellanvändning",
    "llm-override":
      "Tillåt inställning av den föredragna LLM-modellen för att åsidosätta arbetsytans standard.",
    "llm-temp": "Aktivera dynamisk LLM-temperatur",
    "desc-temp":
      "Tillåt inställning av LLM-temperaturen för att åsidosätta arbetsytans standard.",
    "prompt-override": "Aktivera prompt-återställning",
    "desc-override":
      "Tillåt inställning av systemprompten för att åsidosätta arbetsytans standard.",
  },

  "show-toast": {
    "recovery-codes": "Återställningskoder kopierade till urklipp",
    "scraping-website": "Skrapar webbplats - detta kan ta en stund.",
    "fetching-transcript": "Hämtar transkript för YouTube-video.",
    "updating-workspace": "Uppdaterar arbetsyta...",
    "workspace-updated": "Arbetsyta uppdaterad framgångsrikt.",
    "link-uploaded": "Länk uppladdad framgångsrikt",
    "password-reset": "Återställning av lösenord lyckades",
    "invalid-reset": "Ogiltig återställningstoken",
    "delete-option": "Tråden kunde inte tas bort!",
    "thread-deleted": "Tråd borttagen framgångsrikt!",
    "chat-deleted": "Chatten har tagits bort!",
    "failed-delete-chat": "Det gick inte att ta bort chatten. Försök igen.",
    "error-deleting-chat": "Ett fel inträffade när chatten togs bort.",
    "picture-uploaded": "Profilbild uppladdad.",
    "profile-updated": "Profil uppdaterad.",
    "logs-cleared": "Händelseloggar rensade framgångsrikt.",
    "preferences-updated": "Systeminställningar uppdaterade framgångsrikt.",
    "user-created": "Användare skapad framgångsrikt.",
    "user-creation-error": "Det gick inte att skapa användare: ",
    "user-deleted": "Användare borttagen från systemet.",
    "workspaces-saved": "Arbetsytor sparade framgångsrikt!",
    "failed-workspaces": "Misslyckades att spara arbetsytor. Försök igen.",
    "api-deleted": "API-nyckel raderad permanent",
    "api-copied": "API-nyckel kopierad till urklipp",
    "appname-updated": "Anpassat appnamn uppdaterat framgångsrikt.",
    "language-updated": "Språket har uppdaterats.",
    "palette-updated": "Uppdaterad palett.",
    "image-uploaded": "Bild uppladdad framgångsrikt.",
    "logo-removed": "Bild borttagen framgångsrikt.",
    "logo-remove-error": "Error removing logo:",
    "updated-welcome": "Välkomstmeddelanden uppdaterade framgångsrikt.",
    "updated-footer": "Footer-ikoner uppdaterade framgångsrikt.",
    "updated-paragraph": "Anpassad paragraftext uppdaterad framgångsrikt.",
    "updated-supportemail": "Support-e-post uppdaterad framgångsrikt.",
    "stt-success": "Tal-till-text-preferenser sparade framgångsrikt.",
    "tts-success": "Text-till-tal-preferenser sparade framgångsrikt.",
    "failed-chats-export": "Misslyckades att exportera chattar.",
    "chats-exported": "Chattar exporterades framgångsrikt som {{name}}.",
    "cleared-chats": "Alla chattar rensade.",
    "embed-deleted": "Inbäddning borttagen från systemet.",
    "snippet-copied": "Snippet kopierad till urklipp!",
    "embed-updated": "Inbäddning uppdaterad framgångsrikt.",
    "embedding-saved": "Inbäddningspreferenser sparade framgångsrikt.",
    "chunking-settings": "Inställningar för textindelning sparade.",
    "llm-saved": "LLM-preferenser sparade framgångsrikt.",
    "llm-saving-error": "Kunde inte spara LLM-inställningar: ",
    "multiuser-enabled": "Multi-User-läge aktiverat framgångsrikt.",
    "publicuser-enabled": "Public-User-läge aktiverat framgångsrikt.",
    "publicuser-disabled": "Public-User-läge avaktiverat framgångsrikt.",
    "page-refresh": "Din sida kommer att uppdateras om några sekunder.",
    "transcription-saved": "Transkriptionspreferenser sparade framgångsrikt.",
    "vector-saved": "Vektor-databas-preferenser sparade framgångsrikt.",
    "workspace-not-deleted": "Arbetsyta kunde inte raderas!",
    "maximum-messages": "Maximalt 4 meddelanden tillåtna.",
    "users-updated": "Användare uppdaterade framgångsrikt.",
    "vectordb-not-reset": "Arbetsytans vektordatabas kunde inte återställas!",
    "vectordb-reset": "Arbetsytans vektordatabas återställd!",
    "linked-workspaces-updated":
      "Länkade arbetsytor uppdaterades framgångsrikt.",
    "appname-update-error": "Misslyckades att uppdatera anpassat appnamn: ",
    "logo-uploaded": "Logotyp uppladdad framgångsrikt.",
    "logo-upload-error": "Misslyckades att ladda upp logotyp: ",
    "update-welcome-error": "Misslyckades att uppdatera välkomstmeddelanden:",
    "update-footer-error": "Misslyckades att uppdatera footer-ikoner: ",
    "update-paragraph-error":
      "Misslyckades att uppdatera anpassad paragraftext: ",
    "update-supportemail-error": "Misslyckades att uppdatera support-e-post: ",
    "meta-data-update": "Webbplatsinställningar uppdaterade!",
    "reset-tab-name-error":
      "Misslyckades att återställa till standardfliknamn.",
    "update-tab-name-error": "Misslyckades att uppdatera fliknamn: ",
    "updated-website": "Webbplatsinställningar uppdaterade framgångsrikt.",
    "update-website-error": "Misslyckades att uppdatera webbplatslänk: ",
    "reset-website-error":
      "Misslyckades att återställa till standard webbplatslänk.",
    "palette-update-error": "Misslyckades med att uppdatera färgpaletten: ",
    "citation-state-updated":
      "Källhänvisningsstatus uppdaterad. {{citationState}}",
    "citation-state-update-error":
      "Det gick inte att uppdatera källhänvisningsinställningen",
    "citation-update-error":
      "Fel vid uppdatering av källhänvisningsinställningen",
    "message-limit-updated":
      "Meddelandegränsinställningar har uppdaterats framgångsrikt.",
    "flashing-started": "Flashning påbörjad...",
    "flashing-success": "Flashning slutförd",
    "flashing-error": "Fel vid flashning: {{error}}",
    "upgrade-text-error": "Ett fel uppstod vid uppgradering av texten",
    "invoice-logging-state-updated":
      "Inställningar för fakturaloggning har uppdaterats framgångsrikt.",
    "invoice-logging-state-update-error":
      "Fel vid uppdatering av fakturaloggningsstatus: ",
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Dina chattar kommer inte att användas för träning",
        "Dina uppmaningar och dokumenttext som används i svarsskapande är synliga för OpenAI",
      ],
    },
    azure: {
      description: [
        "Dina chattar kommer inte att användas för träning",
        "Din text och inbäddningstext är inte synliga för OpenAI eller Microsoft",
      ],
    },
    anthropic: {
      description: [
        "Dina chattar kommer inte att användas för träning",
        "Dina uppmaningar och dokumenttext som används i svarsskapande är synliga för Anthropic",
      ],
    },
    gemini: {
      description: [
        "Dina chattar är avpersonifierade och används i träning",
        "Dina uppmaningar och dokumenttext som används i svarsskapande är synliga för Google",
      ],
    },
    lmstudio: {
      description: [
        "Din modell och dina chattar är endast åtkomliga på servern som kör LMStudio",
      ],
    },
    localai: {
      description: [
        "Din modell och dina chattar är endast åtkomliga på servern som kör LocalAI",
      ],
    },
    ollama: {
      description: [
        "Din modell och dina chattar är endast åtkomliga på maskinen som kör Ollama-modeller",
      ],
    },
    native: {
      description: [
        "Din modell och dina chattar är endast åtkomliga på denna plattform",
      ],
    },
    togetherai: {
      description: [
        "Dina chattar kommer inte att användas för träning",
        "Dina uppmaningar och dokumenttext som används i svarsskapande är synliga för TogetherAI",
      ],
    },
    mistral: {
      description: [
        "Dina uppmaningar och dokumenttext som används i svar skickas till din Mistral hanterade slutpunkt",
      ],
    },
    huggingface: {
      description: [
        "Dina uppmaningar och dokumenttext som används i svar skickas till din HuggingFace hanterade slutpunkt",
      ],
    },
    perplexity: {
      description: [
        "Dina chattar kommer inte att användas för träning",
        "Dina uppmaningar och dokumenttext som används i svarsskapande är synliga för Perplexity AI",
      ],
    },
    openrouter: {
      description: [
        "Dina chattar kommer inte att användas för träning",
        "Dina uppmaningar och dokumenttext som används i svarsskapande är synliga för OpenRouter",
      ],
    },
    groq: {
      description: [
        "Dina chattar kommer inte att användas för träning",
        "Dina uppmaningar och dokumenttext som används i svarsskapande är synliga för Groq",
      ],
    },
    koboldcpp: {
      description: [
        "Din modell och dina chattar är endast åtkomliga på servern som kör KoboldCPP",
      ],
    },
    textgenwebui: {
      description: [
        "Din modell och dina chattar är endast åtkomliga på servern som kör Oobabooga Text Generation Web UI",
      ],
    },
    "generic-openai": {
      description: [
        "Data delas enligt de användarvillkor som gäller för din generiska slutpunktleverantör.",
      ],
    },
    cohere: {
      description: [
        "Data delas enligt användarvillkoren för cohere.com och dina lokala sekretesslagar.",
      ],
    },
    litellm: {
      description: [
        "Din modell och dina chattar är endast åtkomliga på servern som kör LiteLLM",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Dina vektorer och dokumenttext lagras på din Chroma-instans",
        "Åtkomst till din instans hanteras av dig",
      ],
    },
    pinecone: {
      description: [
        "Dina vektorer och dokumenttext lagras på Pinecones servrar",
        "Åtkomst till dina data hanteras av Pinecone",
      ],
    },
    qdrant: {
      description: [
        "Dina vektorer och dokumenttext lagras på din Qdrant-instans (moln eller egenvärd)",
      ],
    },
    weaviate: {
      description: [
        "Dina vektorer och dokumenttext lagras på din Weaviate-instans (moln eller egenvärd)",
      ],
    },
    milvus: {
      description: [
        "Dina vektorer och dokumenttext lagras på din Milvus-instans (moln eller egenvärd)",
      ],
    },
    zilliz: {
      description: [
        "Dina vektorer och dokumenttext lagras på din Zilliz molnkluster.",
      ],
    },
    astra: {
      description: [
        "Dina vektorer och dokumenttext lagras på din AstraDB molndatabas.",
      ],
    },
    lancedb: {
      description: [
        "Dina vektorer och dokumenttext lagras privat på denna server",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: ["Din dokumenttext integreras privat på denna server"],
    },
    openai: {
      description: [
        "Din dokumenttext skickas till OpenAI-servrar",
        "Dina dokument används inte för träning",
      ],
    },
    azure: {
      description: [
        "Din dokumenttext skickas till din Microsoft Azure-tjänst",
        "Dina dokument används inte för träning",
      ],
    },
    localai: {
      description: [
        "Din dokumenttext integreras privat på servern som kör LocalAI",
      ],
    },
    ollama: {
      description: [
        "Din dokumenttext integreras privat på servern som kör Ollama",
      ],
    },
    lmstudio: {
      description: [
        "Din dokumenttext integreras privat på servern som kör LMStudio",
      ],
    },
    cohere: {
      description: [
        "Data delas enligt användarvillkoren för cohere.com och dina lokala sekretesslagar.",
      ],
    },
    voyageai: {
      description: [
        "Data som skickas till Voyage AIs servrar delas enligt användarvillkoren för voyageai.com.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Redigera",
    response: "Svar",
    prompt: "Uppmaning",
    regenerate: "Generera om svaret",
    good: "Bra svar",
    bad: "Dåligt svar",
    copy: "Kopiera",
    more: "Fler åtgärder",
    fork: "Forka",
    delete: "Radera",
    cancel: "Avbryt",
    save: "Spara & Skicka",
  },

  citations: {
    show: "Visa citat",
    hide: "Dölj citat",
    chunk: "Källcitat",
    pdr: "PDF-markering",
    "pdr-h": "Källmarkering",
    referenced: "Refererad",
    times: "gånger.",
    citation: "Citat",
    match: "match",
    download:
      "Den här webbläsaren stöder inte PDF-filer. Vänligen ladda ner PDF-filen för att visa den:",
    "download-btn": "Ladda ner PDF",
  },

  "document-drafting": {
    title: "Dokumentutkast",
    description: "Kontrollera dina inställningar för dokumentutkast.",
    configuration: "Konfiguration",
    "drafting-model": "Utkast LLM",
    enabled: "Dokumentutkast är aktiverat",
    disabled: "Dokumentutkast är inaktiverat",
    "enabled-toast": "Dokumentutkast aktiverat",
    "disabled-toast": "Dokumentutkast inaktiverat",
    "desc-settings":
      "Administratören kan ändra inställningarna för dokumentutkast för alla användare.",
    "drafting-llm": "Utkast LLM-preferens",
    saving: "Sparar...",
    save: "Spara ändringar",
    "chat-settings": "Chatinställningar",
    "drafting-chat-settings": "Chatinställningar för Dokumentutkast",
    "chat-settings-desc":
      "Kontrollera chatfunktionens beteende för dokumentutkast.",
    "drafting-prompt": "Utkast för Dokumentutkast",
    "drafting-prompt-desc":
      "Prompten som kommer att användas för dokumentutkast. Definiera kontext och instruktioner för AI:n att generera ett svar. Du bör tillhandahålla en noggrant utformad prompt så att AI:n kan generera ett relevant och korrekt svar.",
    linking: "Dokumentlänkning",
    "legal-issues-prompt":
      "Vilka juridiska frågor uppstår i det givna sammanhanget med prompten?",
    "legal-issues-prompt-desc": "Ange prompten för juridiska frågor.",
    "memo-prompt": "Ge ett memo om vart och ett av dessa juridiska problem.",
    "memo-prompt-desc": "Ange prompten för memo.",
    "desc-linkage":
      "Aktivera ytterligare juridisk kontext genom att göra vektor/PDR-sökningar ovanpå memo-hämtning",
  },

  "dd-settings": {
    title: "Dokumentredigeringsinställningar",
    description:
      "Kontrollera tokengränser och beteende för dokumentredigeringsfunktioner",
    "vector-search": {
      title: "Vektorsökning",
      description: "Använd vektorsökning för att hitta relevanta dokument",
    },
    "memo-generation": {
      title: "Memogenerering",
      description: "Generera juridiska PM för sökkontext",
    },
    "base-generation": {
      title: "Grundläggande Juridisk Analys",
      description:
        "Generera initial juridisk problemanalys från användarfrågor",
    },
    "linked-workspace-impact": {
      title: "Länkad Arbetsytepåverkan",
      description:
        "Justera tillgängliga tokens baserat på antal länkade arbetsytor",
    },
    "vector-token-limit": {
      title: "Vektortokengräns",
      description:
        "Maximalt antal token per länkad arbetsyta för vektorsökning",
    },
    "memo-token-limit": {
      title: "Memotokengräns",
      description: "Maximalt antal token för generering av juridiska PM",
    },
    "base-token-limit": {
      title: "Bastokengräns",
      description: "Maximalt antal token för hämtning av basinnehåll",
    },
    "toast-success": "Inställningar uppdaterade",
    "toast-fail": "Kunde inte uppdatera inställningar",
  },

  modale: {
    document: {
      title: "Mina Dokument",
      document: "Dokument",
      search: "Sök efter dokument",
      folder: "Ny Mapp",
      name: "Namn",
      empty: "Inga Dokument",
      "move-workspace": "Flytta till Arbetsyta",
      "doc-processor": "Dokumenthanterare Otillgänglig",
      "processor-offline":
        "Vi kan inte ladda upp dina filer just nu eftersom dokumenthanteraren är offline. Försök igen senare.",
      "drag-drop": "Klicka för att ladda upp eller dra och släpp",
      "supported-files":
        "stöder många filformat men sökbar PDF är bäst för källmarkering m.m.",
      "submit-link": "eller skicka en länk",
      fetch: "Hämta webbplats",
      fetching: "Hämtar...",
      "file-desc":
        "Dessa filer kommer att laddas upp till dokumenthanteraren som används på den här servern. Dessa filer skickas inte eller delas med tredje part.",
      cost: "*Engångskostnad för inbäddningar",
      "save-embed": "Spara och Bädda in",
      "loading-message": "Detta kan ta ett tag för stora dokument",
      "failed-uploads": "Kunde inte ladda upp följande filer",
    },
    connectors: {
      title: "Datakontakter",
      search: "Sök datakontakter",
      empty: "Inga datakontakter hittades.",
    },
  },

  dataConnectors: {
    github: {
      name: "GitHub Repo",
      description:
        "Importera ett helt offentligt eller privat GitHub-repo med ett enda klick.",
      url: "GitHub Repo URL",
      "collect-url": "URL för det GitHub-repo du vill samla in.",
      "access-token": "GitHub Åtkomsttoken",
      optional: "valfritt",
      "rate-limiting": "Åtkomsttoken för att förhindra hastighetsbegränsning.",
      "desc-picker":
        "När det är klart kommer alla filer att vara tillgängliga för inbäddning i arbetsytorna i dokumentväljaren.",
      branch: "Gren",
      "branch-desc": "Grenen du vill samla in filer från.",
      "branch-loading": "-- laddar tillgängliga grenar --",
      "desc-start": "Utan att fylla i",
      "desc-token": "GitHub Åtkomsttoken",
      "desc-connector": "denna datakontakt kommer endast att kunna samla in",
      "desc-level": "översta nivåns",
      "desc-end":
        "filer i repoet på grund av GitHubs offentliga API-begränsningar.",
      "personal-token":
        "Få en gratis personlig åtkomsttoken med ett GitHub-konto här.",
      without: "Utan en",
      "personal-token-access": "Personlig Åtkomsttoken",
      "desc-api":
        ", kan GitHub API begränsa antalet filer som kan samlas in på grund av hastighetsbegränsningar. Du kan",
      "temp-token": "skapa en tillfällig åtkomsttoken",
      "avoid-issue": "för att undvika detta problem.",
      submit: "Skicka",
      "collecting-files": "Samlar in filer...",
    },
    "youtube-transcript": {
      name: "YouTube Transkript",
      description:
        "Importera transkriptionen av en hel YouTube-video från en länk.",
      url: "YouTube Video URL",
      "url-video": "URL för YouTube-videon du vill transkribera.",
      collect: "Samla in transkript",
      collecting: "Samlar in transkript...",
      "desc-end":
        "När det är klart kommer transkriptionen att vara tillgänglig för inbäddning i arbetsytorna i dokumentväljaren.",
    },
    "website-depth": {
      name: "Bulk Link Scraper",
      description:
        "Skrapa en webbplats och dess underlänkar upp till en viss djup.",
      url: "Webbplats URL",
      "url-scrape": "URL för webbplatsen du vill skrapa.",
      depth: "Djup",
      "child-links":
        "Detta är antalet underlänkar som arbetaren ska följa från ursprungs-URL:en.",
      "max-links": "Max Länkar",
      "links-scrape": "Maximalt antal länkar att skrapa.",
      scraping: "Skrapar webbplats...",
      submit: "Skicka",
      "desc-scrap":
        "När det är klart kommer alla skrapade sidor att vara tillgängliga för inbäddning i arbetsytorna i dokumentväljaren.",
    },
    confluence: {
      name: "Confluence",
      description: "Importera en hel Confluence-sida med ett enda klick.",
      url: "Confluence Sid URL",
      "url-page": "URL för en sida i Confluence-utrymmet.",
      username: "Confluence Användarnamn",
      "own-username": "Ditt Confluence-användarnamn.",
      token: "Confluence Åtkomsttoken",
      "desc-start":
        "Du måste ange en åtkomsttoken för autentisering. Du kan generera en åtkomsttoken",
      here: "här",
      access: "Åtkomsttoken för autentisering.",
      collecting: "Samlar in sidor...",
      submit: "Skicka",
      "desc-end":
        "När det är klart kommer alla sidor att vara tillgängliga för inbäddning i arbetsytorna.",
    },
  },

  module: {
    "legal-qa": "Rättsutredning",
    "document-drafting": "Dokumentutkast",
  },

  "fine-tune": {
    title: "Du har tillräckligt med data för en fine-tune!",
    link: "klicka för att lära dig mer",
    dismiss: "avvisa",
  },

  mobile: {
    disclaimer:
      "VARNING: För bästa upplevelse och full tillgång till alla funktioner, vänligen använd en dator för att komma åt appen.",
  },
  onboarding: {
    welcome: "Välkommen till",
    "get-started": "Kom igång",
    "llm-preference": {
      title: "LLM-preferens",
      description:
        "ISTLLM kan arbeta med många LLM-leverantörer. Detta kommer att vara tjänsten som hanterar chattningen.",
      "LLM-search": "Sök LLM-leverantörer",
    },
    "user-setup": {
      title: "Användarinställningar",
      description: "Konfigurera dina användarinställningar.",
      "sub-title": "Hur många personer kommer att använda din instans?",
      "single-user": "Bara jag",
      "multiple-user": "Mitt team",
      "setup-password": "Vill du ställa in ett lösenord?",
      "password-requirment": "Lösenord måste vara minst 8 tecken.",
      "save-password":
        "Det är viktigt att spara detta lösenord eftersom det inte finns någon återställningsmetod.",
      "password-label": "Instanslösenord",
      username: "Admin konto användarnamn",
      password: "Admin konto lösenord",
      "account-requirment":
        "Användarnamn måste vara minst 6 tecken långt och bara innehålla gemenser, siffror, understreck och inga mellanslag. Lösenord måste vara minst 8 tecken långt.",
      "password-note":
        "Som standard kommer du att vara den enda admin. När onboardingen är klar kan du skapa och bjuda in andra att bli användare eller administratörer. Förlora inte ditt lösenord eftersom endast administratörer kan återställa lösenord.",
    },
    "data-handling": {
      title: "Datahantering och Integritet",
      description:
        "Vi är engagerade i transparens och kontroll när det gäller dina personuppgifter.",
      "llm-label": "LLM-val",
      "embedding-label": "Inbäddningspreferens",
      "database-lablel": "Vektordatabas",
      "reconfigure-option":
        "Dessa inställningar kan omkonfigureras när som helst i inställningarna.",
    },
    survey: {
      title: "Välkommen till IST Legal LLM",
      description:
        "Hjälp oss göra IST Legal LLM anpassad för dina behov. Frivilligt.",
      email: "Vad är din e-post?",
      usage: "Vad kommer du att använda denna instans till?",
      work: "För arbete",
      "personal-use": "För personligt bruk",
      other: "Annat",
      comment: "Några kommentarer till teamet?",
      optional: "(Frivilligt)",
      feedback: "Tack för din feedback!",
    },
    button: {
      yes: "Ja",
      no: "Nej",
      "skip-survey": "Hoppa över undersökningen",
      ok: "OK",
      adjust: "Verkställ ändringsförslag",
    },
    placeholder: {
      "admin-password": "Ditt admin lösenord",
      "admin-username": "Ditt admin användarnamn",
      "email-example": "du@gmail.com",
      comment:
        "Om du har några frågor eller kommentarer just nu, kan du lämna dem här och vi återkommer till dig. Du kan också skicka e-post till richard.sahlberg@foytech.se",
    },
  },
  "default-settings": {
    title: "Standardinställningar för Juridiska Frågor och Svar",
    "default-desc":
      "Styr standardbeteendet för arbetsytor för Juridiska Frågor och Svar",
    prompt: "Juridiskt Fråga och Svar Systemprompt",
    "prompt-desc":
      "Standardprompten som kommer att användas i Juridiska Frågor och Svar. Definiera sammanhanget och instruktionerna för att AI:n ska kunna generera ett svar. Du bör tillhandahålla en noggrant utformad prompt så att AI:n kan generera ett relevant och korrekt svar.",
    "toast-success": "Standardsystemprompt uppdaterad",
    "toast-fail": "Misslyckades med att uppdatera systemprompten",
    snippets: {
      title: "Standard för max antal sammanhangsutdrag",
      description:
        "Denna inställning styr det maximala antalet sammanhangsutdrag som skickas till LLM per chatt eller fråga.",
      recommend: "Rekommenderat: 10",
    },
    "validation-prompt": {
      title: "Valideringsprompt",
      description:
        "Denna inställning styr standardvalideringsprompten som kommer att skickas till LLM för att validera det givna svaret.",
      placeholder:
        "Vänligen validera följande svar genom att kontrollera alla juridiska referenser och citat för korrekthet mot det givna sammanhanget. Lista eventuella felaktigheter eller missrepresentationer som hittas.",
    },
  },
  "confirm-message": {
    "delete-doc":
      "Är du säker på att du vill ta bort dessa filer och mappar?\nDetta kommer att ta bort filerna från systemet och automatiskt ta bort dem från befintliga arbetsytor.\nDenna åtgärd kan inte ångras.",
  },
  performLegalTask: {
    title: "Utför juridisk uppgift",
    description:
      "Aktivera eller inaktivera knappen för juridiska uppgifter i dokumentutkastet.",
    successMessage: "Den juridiska uppgiften har {{status}}",
    failureUpdateMessage:
      "Misslyckades med att uppdatera inställningen för juridisk uppgift.",
    errorSubmitting:
      "Fel vid inlämning av inställningarna för juridisk uppgift.",
  },
  statuses: {
    enabled: "aktiverad",
    disabled: "inaktiverad",
  },
  "answer-upgrade": {
    title: "Förädla Svaret",
    "text-upgrade-prompt":
      "Prompt: {{prompt}}. Returnera endast den ändrade versionen av exakt denna text, inget annat: {{selectedText}}",
    "category-step": {
      title: "Välj Kategori",
      description: "Välj kategori som bäst matchar önskemål",
      categories: {
        formality: {
          label: "Formalitet",
          choices: {
            more_formal: "Gör svaret mer formellt",
            less_formal: "Gör svaret mindre formellt",
            more_professional: "Gör svaret mer professionellt",
            more_casual: "Gör svaret mer vardagligt",
            more_polished: "Gör svaret mer polerat",
            more_relaxed: "Gör svaret mer avslappnat",
            academic_tone: "Använd en mer akademisk ton",
            conversational_tone: "Använd en mer samtalsliknande ton",
          },
        },
        complexity: {
          label: "Språkkomplexitet",
          choices: {
            simplify: "Förenkla språket",
            more_descriptive: "Lägg till mer beskrivande språk",
            complex_vocab: "Använd mer komplext ordförråd",
            simple_vocab: "Använd enklare ordförråd",
            technical: "Öka användningen av tekniskt språk",
            layman: "Öka användningen av lekmannatermer",
            add_jargon: "Inkludera fackspråk",
            avoid_jargon: "Undvik fackspråk och använd allmänna termer",
            add_rhetorical: "Lägg till fler retoriska frågor",
            less_rhetorical: "Använd färre retoriska frågor",
          },
        },
        structure: {
          label: "Meningsstruktur",
          choices: {
            shorter: "Förkorta meningarna",
            longer: "Förläng meningarna",
            vary: "Variera meningsstrukturen",
            standardize: "Standardisera meningsstrukturen",
            more_complex: "Använd mer komplexa meningar",
            simpler: "Använd enklare meningar",
            active_voice: "Öka användningen av aktiv form",
            passive_voice: "Öka användningen av passiv form",
          },
        },
        figurative: {
          label: "Bildligt språk",
          choices: {
            more_figurative: "Öka användningen av bildligt språk",
            less_figurative: "Minska användningen av bildligt språk",
            metaphors: "Lägg till fler metaforer och liknelser",
            literal: "Använd mer bokstavligt språk",
            more_idioms: "Inkludera fler idiom",
            less_idioms: "Minska användningen av idiom",
            more_symbolism: "Öka användningen av symbolik",
            less_symbolism: "Minska användningen av symbolik",
          },
        },
        conciseness: {
          label: "Omfattning",
          choices: {
            more_concise: "Gör svaret mer koncist",
            more_wordy: "Gör svaret mer ordrikt",
            remove_redundant: "Ta bort överflödiga fraser",
            add_details: "Använd mer detaljerade förklaringar",
            reduce_filler: "Minska användningen av utfyllnadsord",
            add_elaboration: "Utveckla med mer utförliga förklaringar",
          },
        },
        imagery: {
          label: "Bildspråk och Sensoriska Detaljer",
          choices: {
            enhance_imagery: "Utöka användningen av bildspråk",
            simplify_imagery: "Förenkla bildspråket",
            vivid_descriptions: "Använd mer levande beskrivningar",
            straightforward_descriptions: "Använd mer direkta beskrivningar",
            more_visual: "Inkludera fler visuella detaljer",
            less_visual: "Fokusera mindre på visuella detaljer",
          },
        },
        paragraph: {
          label: "Stycke- och Textstruktur",
          choices: {
            shorter_paragraphs: "Gör styckena kortare",
            longer_paragraphs: "Gör styckena längre",
            break_sections: "Dela upp texten i mindre sektioner",
            combine_sections: "Kombinera sektioner för bättre flöde",
            more_lists: "Använd fler punktlistor",
            more_continuous: "Använd i högre grad sammanhängande text",
            vary_paragraphs: "Öka styckevariationen",
            consistent_length: "Upprätthåll konsekvent styckelängd",
          },
        },
        other: {
          label: "Andra Aspekter",
          choices: {
            replace_context: "Ersätt CONTEXT-referenser med faktiskt källnamn",
            add_numbering: "Lägg till styckenumrering",
            remove_numbering: "Ta bort styckenumrering",
            extend_statutories: "Utöka texter om lagar",
            reduce_statutories: "Minska texter om lagar",
            extend_jurisprudence: "Utöka texter om rättspraxis",
            reduce_jurisprudence: "Minska texter om rättspraxis",
          },
        },
      },
    },
    "prompt-step": {
      title: "Välj Prompt",
      description: "Välj hur du vill förädla svaret",
    },
    actions: {
      next: "Nästa",
      back: "Tillbaka",
      upgrade: "Förädla Svar",
      cancel: "Avbryt",
    },
  },

  "pdr-settings": {
    title: "PDR-inställningar",
    description:
      "Kontrollera mekanismerna för hantering av dynamisk token-begränsning för PDR-algoritmen",
    "desc-end":
      "Du bör bara ändra denna inställning om du förstår hur PDR-algoritmen fungerar.",
    "pdr-token-limit": "PDR Token-gräns",
    "pdr-token-limit-desc":
      "Det maximala antalet tokens som används för PDR-algoritmen.",
    "input-prompt-token-limit": "Token-gräns för inmatningsuppmaning",
    "input-prompt-token-limit-desc":
      "Det maximala antalet tokens som används för inmatningsuppmaningen.",
    "response-token-limit": "Token-gräns för svar",
    "response-token-limit-desc":
      "Det maximala antalet tokens som används för svaret.",
    "toast-success": "PDR-inställningar uppdaterade",
    "toast-fail": "Misslyckades med att uppdatera PDR-inställningar",
    "adjacent-vector-limit": "Gräns för Närliggande Vektorer",
    "adjacent-vector-limit-desc":
      "Antalet närliggande vektorer som kommer att användas för PDR-algoritmen.",
  },
  "validate-response": {
    title: "Valideringsresultat",
    "toast-fail": "Kunde inte validera svar",
    validating: "Validerar svar",
    button: "Validera svar",
    "adjust-prefix":
      "Genomför alla påkallade ändringar av svaret utifrån denna återkoppling: ",
    "adjust-button": "Verkställ ändringsförslag",
  },

  "workspace-names": {
    "Administrative Law": "Förvaltningsrätt",
    "Business Law": "Affärsrätt",
    "Civil Law": "Civilrätt",
    "Criminal Law": "Straffrätt",
    "Diplomatic Law": "Diplomaträtt",
    "Fundamental Law": "Grundlag",
    "Human Rights Law": "Mänskliga rättigheter",
    "Judicial Laws": "Rättslagar",
    "Security Laws": "Säkerhetslagar",
    "Taxation Laws": "Skattelagar",
  },
  "validate-answer": {
    setting: "Validering LLM",
    title: "Validering LLM-preferens",
    description:
      "Det här är referenserna och inställningarna för din föredragna validerings-LLM chatt- och embedding-leverantör. Det är viktigt att dessa nycklar är aktuella och korrekta, annars kommer systemet inte att fungera korrekt.",
    "toast-success": "Valideringsinställningar för LLM uppdaterade",
    "toast-fail":
      "Misslyckades med att uppdatera valideringsinställningar för LLM",
    saving: "Sparar...",
    "save-changes": "Spara ändringar",
  },
};

export default TRANSLATIONS;
